import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './firebase';
import Layout from './Layout';
import styled from 'styled-components';
import Post from './Post';
import { getProfilePicture } from './utils/getProfilePicture'; // Import utility function
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import EditProfile from './EditProfile';

function MyProfile() {
  const [userData, setUserData] = useState(null);
  const [subjectNames, setSubjectNames] = useState([]);
  const [chosenCourses, setChosenCourses] = useState([]);
  const [posts, setPosts] = useState([]);
  const [postViews, setPostViews] = useState({});
  const [totalViews, setTotalViews] = useState(0); // For total post views
  const [profileViews, setProfileViews] = useState(0); // For profile views
  const [searchInput, setSearchInput] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate(); // Initialize navigate
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const data = userDocSnap.data();
            setUserData(data);
            setChosenCourses(data.subjects || []);
            setProfileViews(data.profileViews || 0); // Fetch profile views
            setTotalViews(data.views || 0); // Set total views

            // Fetch subject names
            if (data.subjects?.length > 0) {
              const subjectsCollection = collection(db, 'kurser');
              const subjectPromises = data.subjects.map(async (subjectId) => {
                const subjectDoc = await getDoc(doc(subjectsCollection, subjectId));
                if (subjectDoc.exists()) {
                  return subjectDoc.data().name;
                }
                return 'Unknown Subject';
              });

              const subjectNamesArray = await Promise.all(subjectPromises);
              setSubjectNames(subjectNamesArray);
            }

            // Fetch user posts
            const postsQuery = query(collection(db, 'posts'), where('ownerUID', '==', currentUser.uid));
            const postDocs = await getDocs(postsQuery);
            const userPosts = postDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPosts(userPosts);
            setFilteredPosts(userPosts);

            // Fetch post views and calculate total
            const viewsDocRef = doc(db, 'users', currentUser.uid, 'views');
            const viewsDocSnap = await getDoc(viewsDocRef);
            if (viewsDocSnap.exists()) {
              const viewsData = viewsDocSnap.data();
              setPostViews(viewsData);

              // Calculate total post views
              const totalPostViews = Object.values(viewsData).reduce((sum, viewCount) => sum + viewCount, 0);
              setTotalViews(totalPostViews);
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    const filtered = posts.filter(post =>
      post.title.toLowerCase().includes(searchInput.toLowerCase()) ||
      (post.content && post.content.toLowerCase().includes(searchInput.toLowerCase()))
    );
    setFilteredPosts(filtered);
  }, [searchInput, posts]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubjectClick = (subject) => {
    navigate(`/search?keyword=${encodeURIComponent(subject)}`); // Redirect to search with the subject as a keyword
  };

  const handleEditClick = () => {
    setEditMode(true); // Open the edit form
  };

  const handleCloseEdit = () => {
    setEditMode(false); // Close the edit form
  };


  if (loading) {
    return <Layout><div></div></Layout>;
  }

  if (!userData) {
    return <Layout><div>User data not available.</div></Layout>;
  }

  return (
    <Layout>
      <ProfileContainer>
        <MainContent>
          <ProfileSection>
            {editMode && <EditProfile currentUser={currentUser} onClose={handleCloseEdit} />}
            <YearsTeachingContainer>
              <YearsText>{userData?.yearsOfTeaching || 0}</YearsText>
              <YearsTeaching><span>+</span></YearsTeaching>
            </YearsTeachingContainer>
            <HorizontalLine />
            <EditButton onClick={handleEditClick}>
              <EditIcon src="/edit.png" alt="Edit Profile" />
            </EditButton>
            <ProfilePic src={getProfilePicture(userData)} alt="Profile Picture" />
            <ProfileDetails>
              <NameAndJobContainer>
                <UserName>{userData.fullName || 'N/A'}</UserName>
                <JobDescription>
                {userData.selectedOption === 1 ? 'Grundskolelärare' : 'Gymnasielärare'} på {userData.currentSchool || 'N/A'}
                {userData.hideEmail === false && ` (${currentUser.email})`}
              </JobDescription>
              </NameAndJobContainer>
              <SubjectContainer>
                {subjectNames.map((subject, index) => (
                  <SubjectButton key={index} onClick={() => handleSubjectClick(subject)}>
                    {subject}
                  </SubjectButton>
                ))}
              </SubjectContainer>
            </ProfileDetails>
          </ProfileSection>
  
          {/* Analytics Section */}
          <AnalyticsSection>
            <AnalyticsHeader>
              <AnalyticsTitle>Statistik</AnalyticsTitle>
              <PrivateText>privat (bara du)</PrivateText>
            </AnalyticsHeader>
            <AnalyticsContent>
              <ProfileViewsContainer>{Math.ceil(profileViews / 2)} Profilbesök</ProfileViewsContainer>
              <PostViewsContainer>{Math.ceil(profileViews / 2)} Visningar av inlägg</PostViewsContainer>
            </AnalyticsContent>
          </AnalyticsSection>
  
          {/* Posts Section */}
          <PostsSection>
            <SearchForm onSubmit={handleSearchSubmit}>
              <SearchInput
                type="text"
                placeholder="Sök bland mina inlägg"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </SearchForm>
  
            {filteredPosts.length > 0 ? (
              filteredPosts.map(post => (
                <PostContainer key={post.id}>
                  <Post postId={post.id} style={{ width: '100px' }} />
                </PostContainer>
              ))
            ) : (
              <p>Inga sökträffar.</p>
            )}
          </PostsSection>
        </MainContent>
      </ProfileContainer>
    </Layout>
  );
  
}

// Styled-components for the new Analytics block

const AnalyticsSection = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  text-align: left;
  margin-top: 20px;
`;

const AnalyticsHeader = styled.div`
  display: flex;
  justify-content: flex-start; /* Align everything to the left */
  align-items: center;
  margin-left: 20px; /* Add margin to move everything slightly to the right */
  gap: 10px; /* Add some space between the title and the text */
`;

const AnalyticsTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 10px;
  display: inline-block;
`;

const PrivateText = styled.span`
  font-size: 14px;
  color: #888;
  position: relative;
  top: -4px; /* Move the text slightly upwards */
`;

const AnalyticsContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
`;

const ProfileViewsContainer = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  width: 50%;
  margin: 0;
  border: 1px solid #ddd;
`;

const PostViewsContainer = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  width: 50%;
  margin: 0;
  border: 1px solid #ddd;
`;

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 1000px;
  width: 700px;
  margin: 0 auto;
  border-radius: 8px;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PostsSection = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 700px;
  text-align: left;
`;

const PostContainer = styled.div`
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const ProfileSection = styled.div`
  text-align: left;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  padding-top: 130px;
  width: 100%;
`;

const ProfileDetails = styled.div`
  font-size: 20px;
  position: relative;
`;

const NameAndJobContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
`;

const HorizontalLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ddd;
  top: 75px;
`;

const UserName = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
`;

const JobDescription = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
`;

const ProfilePic = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 30px;
  left: 7%;
  border: 1px solid #ddd;
`;

const SubjectContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SubjectButton = styled.button`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const YearsTeachingContainer = styled.div`
  position: absolute;
  top: 23px;
  right: 20px;
  display: flex;
  align-items: center;
`;

const YearsText = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-right: 1px;
`;

const YearsTeaching = styled.div`
  width: 30px;
  height: 30px;
  font-size: 26px;
  font-weight: bold;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const SearchForm = styled.form`
  width: 300px;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;


const EditButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 65px;
  margin-top: 20px;
  padding: 0;
  border-radius: 50%;


  &:hover img {
    background-color: #F0F0F0;
  }
`;

const EditIcon = styled.img`
  width: 30px; /* Adjust size as necessary */
  height: 30px; /* Adjust size as necessary */
  border-radius: 50%;
  padding: 5px;
`;


export default MyProfile;
