import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from "firebase/firestore";

import {
  signInWithEmailAndPassword,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth, googleProvider, db } from './firebase';

function Login() {
  const [showPassword, setShowPassword] = useState(false); // Add this state to toggle password visibility
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [error, setError] = useState('');
  const [selectedElement, setSelectedElement] = useState(null); // State to track selected input
  const [selectedImage, setSelectedImage] = useState(null); // State to track selected image
  const navigate = useNavigate();

  // Immediately redirect if user is already logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If user is already authenticated, redirect to /homepage
        navigate('/homepage', { replace: true });
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    const persistence = keepLoggedIn ? browserLocalPersistence : browserSessionPersistence;

    try {
      await setPersistence(auth, persistence);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/homepage'); // Redirect to homepage after successful login
    } catch (error) {
      console.error('Login error:', error.code); // Log the error code for debugging
      setError(`Login failed: ${error.message}`); // Display the error message to users
    }
  };


  
  const handleGoogleLogin = async () => {
    const persistence = keepLoggedIn ? browserLocalPersistence : browserSessionPersistence;
  
    try {
      await setPersistence(auth, persistence);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const email = user.email;
  
      // Query Firestore to check if the user exists based on their email
      const usersRef = collection(db, 'users'); // Adjust the 'users' collection name to match your structure
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        // If no users with this email are found, redirect to register
        navigate('/register');
      } else {
        // If user exists, redirect to homepage
        navigate('/homepage');
      }
    } catch (error) {
      setError('Failed to log in with Google.');
      console.error('Google login error:', error);
    }
  };
  

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleLogin}>
        <h2 style={styles.heading}>Logga in</h2>
        {error && <p style={styles.error}>{error}</p>}
        <div style={styles.formContentContainer}>
          <div style={styles.formGroup}>
            <input
              type="email"
              placeholder="Enter your email"
              style={selectedElement === 'email' ? { ...styles.input, ...styles.selected } : styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setSelectedElement('email')}
              required
            />
          </div>
          <div style={styles.formGroup}>
          <div style={styles.passwordContainer}> {/* Wrap the input and button together */}
            <input
              type={showPassword ? 'text' : 'password'} // Change type based on showPassword state
              placeholder="Enter your password"
              style={selectedElement === 'password' ? { ...styles.input, ...styles.selected } : styles.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setSelectedElement('password')}
              required
            />
            <button
              type="button"
              style={styles.showPasswordButton}
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </div>
          </div>
          <div style={styles.orContainer}>
            <div style={styles.line}></div>
            <p style={styles.or}>or</p>
            <div style={styles.line}></div>
          </div>
          <div style={styles.socialLoginContainer}>
            <img
              src="/google.png"
              alt="Log in with Google"
              style={selectedImage === 'google' ? { ...styles.socialImage, ...styles.selected } : styles.socialImage}
              onClick={handleGoogleLogin}
            />
            <img
              src="/microsoft.png"
              alt="Log in with Microsoft"
              style={selectedImage === 'microsoft' ? { ...styles.socialImage, ...styles.selected } : styles.socialImage}
            />
          </div>
          <div style={styles.rememberMe}>
            <label>
              <input
                type="checkbox"
                checked={keepLoggedIn}
                onChange={(e) => setKeepLoggedIn(e.target.checked)}
              />
              Kom ihåg mig
            </label>
          </div>
          <div style={styles.registerLink}>
            <p>
              Har du inget konto? <Link to="/register" style={styles.link}>Registrera dig</Link>
            </p>
          </div>
        </div>
        <button type="submit" style={styles.button}>Logga in</button>
      </form>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#FFFFFF',
  },
  form: {
    width: '350px',

    padding: '20px',
    borderRadius: '20px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f7f7f7',
    border: '1px solid #ccc',
  },
  heading: {
    marginBottom: '20px',
    textAlign: 'center',
    fontSize: '26px',
  },
  formContentContainer: {
    width: '65%',
  },
  formGroup: {
    marginBottom: '15px',
    width: '100%',
  },
  input: {
    width: '90%',
    padding: '10px',
    marginTop: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '13px',
  },
  selected: {
    border: '1px solid #0077b6',
    outline: 'none',
  },
  rememberMe: {
    marginBottom: '15px',
    width: '100%',
    fontSize: '13px',
    marginTop: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  orContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',
  },
  line: {
    flex: 1,
    height: '1px',
    backgroundColor: '#ccc',
    margin: '0 20px',
  },
  or: {
    margin: '0 10px',
    fontSize: '14px',
    color: '#888',
  },
  socialLoginContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    width: '100%',
  },
  socialImage: {
    width: '48%',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
  },
  button: {
    width: '50%',
    padding: '10px',
    backgroundColor: '#0077b6',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '15px',
  },
  error: {
    color: 'red',
    marginBottom: '15px',
  },
  registerLink: {
    marginBottom: '15px',
    fontSize: '13px',
    width: '100%',
    marginTop: '10px',
    textAlign: 'center',
  },
  link: {
    color: '#0077b6',
    textDecoration: 'none',
  },
  passwordContainer: {
    position: 'relative', // Allows placing the button inside the input container
  },
  showPasswordButton: {
    position: 'absolute',
    right: '10px', // Place the button inside the input, aligned to the right
    top: '58%',
    transform: 'translateY(-50%)', // Center the button vertically
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0077b6',
    cursor: 'pointer',
    fontSize: '12px',
  }
  
};

export default Login;
