import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Import your Firebase configuration
import styled from 'styled-components';

function Register2() {
  const [searchTerm, setSearchTerm] = useState('');
  const [courses, setCourses] = useState([]); // Stores the filtered courses from Firebase
  const [chosenCourses, setChosenCourses] = useState([]); // Stores the selected course IDs
  const coursesBlockRef = useRef(null); // Create a reference for the coursesBlock
  const navigate = useNavigate();
  const location = useLocation();
  const selectedType = location.state?.selectedOption || ''; // Provide a fallback value

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'kurser'));
        const coursesData = querySnapshot.docs
          .map(doc => ({ id: doc.id, name: doc.data().name, type: doc.data().type }))
          .filter(course => course.type === selectedType); // Filter courses by selected type
        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, [selectedType]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCourseClick = (course) => {
    if (!chosenCourses.includes(course.id)) {
      setChosenCourses([...chosenCourses, course.id]);
    }
    setSearchTerm(''); // Clear search after adding a course
  };

  const handleRemoveCourse = (courseId) => {
    setChosenCourses(chosenCourses.filter((id) => id !== courseId));
  };

  const filteredCourses = courses
    .filter(course => 
      course.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(course => !chosenCourses.includes(course.id)); // Exclude already chosen courses

  useEffect(() => {
    if (coursesBlockRef.current) {
      // Scroll to the bottom of the coursesBlock whenever chosenCourses changes
      coursesBlockRef.current.scrollTop = coursesBlockRef.current.scrollHeight;
    }
  }, [chosenCourses]);

  const handleNext = () => {
    if (chosenCourses.length > 0) {
      navigate('/register3', { state: { ...location.state, subjects: chosenCourses } });
    } else {
      alert('Du behöver åtminstonde välja ett ämne.');
    }
  };

  return (
    <Container>
      <FormContainer>
        <LogoContainer>
          <LogoImage src="/teachrlogotype.png" alt="Teachr Logo" />
          <LogoText>Teachr</LogoText>
        </LogoContainer>

        <Form>
          <Heading>Skapa ett konto</Heading>
          <FormContentContainer>
            <FormGroup>
              <Input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Sök och välj dina kurser*"
              />
              {searchTerm && (
                <Suggestions>
                  {filteredCourses.map(course => (
                    <SuggestionItem
                      key={course.id}
                      onClick={() => handleCourseClick(course)}
                    >
                      {course.name}
                    </SuggestionItem>
                  ))}
                </Suggestions>
              )}
              <CoursesBlock ref={coursesBlockRef}>
                {chosenCourses.length > 0 ? (
                  chosenCourses.map(courseId => {
                    const course = courses.find(c => c.id === courseId);
                    return (
                      <ChosenCourseContainer key={courseId}>
                        <ChosenCourse>{course.name}</ChosenCourse>
                        <RemoveCross onClick={() => handleRemoveCourse(courseId)}>
                          ×
                        </RemoveCross>
                      </ChosenCourseContainer>
                    );
                  })
                ) : (
                  <NoCoursesText>Inga program tillagda ännu</NoCoursesText>
                )}
              </CoursesBlock>
            </FormGroup>
            <Continue>
              <Square filled />
              <Square filled={chosenCourses.length > 0} />
              <Square />
              <NextButton type="button" onClick={handleNext}>
                Nästa
              </NextButton>
            </Continue>
          </FormContentContainer>
        </Form>
      </FormContainer>
    </Container>
  );
}

// Styled-components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: -120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  pointer-events: none;
`;

const LogoImage = styled.img`
  width: 52px;
  height: 52px;
`;

const LogoText = styled.p`
  font-size: 52px;
  font-weight: bold;
  color: #000;
`;

const Form = styled.form`
  width: 350px;
  height: 450px;
  padding: 20px;
  border-radius: 20px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  position: relative;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  font-size: 26px;
`;

const FormContentContainer = styled.div`
  width: 65%;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #0077b6;
  font-size: 13px;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const Suggestions = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 5px;
  max-height: 150px;
  font-size: 13px;
  overflow-y: auto;
  z-index: 1000;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;

  
`;

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  text-align: center;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const CoursesBlock = styled.div`
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
  max-height: 219px;
  background-color: #f7f7f7;
  text-align: center;
  width: 90%;
  overflow-y: auto;
`;

const ChosenCourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
`;

const ChosenCourse = styled.p`
  font-size: 13px;
  margin: 0;
`;

const RemoveCross = styled.span`
  cursor: pointer;
  color: red;
  font-size: 18px;
  margin-left: 10px;
`;

const NoCoursesText = styled.p`
  font-size: 13px;
`;

const Continue = styled.div`
  position: absolute;
  top: 407px;
  display: flex;
  align-items: center;
  height: 35.5px;
  width: 227.5px;
`;

const NextButton = styled.button`
  width: 50%;
  padding: 10px;
  background-color: #0077b6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 25px;

  &:hover {
    background-color: #005f8a;
  }
`;

const Square = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  margin-left: 8px;
  background: ${(props) => (props.filled ? '#0077b6' : '#fff')};
`;

export default Register2;
