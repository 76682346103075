import React from 'react';
import styled from 'styled-components';
import CreatePost from './CreatePost'; // Import the existing CreatePost component

function CreatePostModal({ showModal, closeModal }) {
  if (!showModal) return null; // Only render if modal should be shown

  // Handler for clicking the overlay, asking for confirmation before closing
  const handleOverlayClick = () => {
    const confirmClose = window.confirm('Är du säker på att du vill stänga ditt inlägg?');
    if (confirmClose) {
      closeModal(); // Close the modal if the user confirms
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}> {/* Clicking the overlay triggers confirmation */}
      <ModalContainer onClick={(e) => e.stopPropagation()}> {/* Prevent closing when clicking inside the modal */}
        <CreatePost closeModal={closeModal} /> {/* Pass closeModal as a prop */}
      </ModalContainer>
    </ModalOverlay>
  );
}

export default CreatePostModal;

// Styled Components for Modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Highest z-index to appear on top of everything */
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  max-width: 450px;
  max-height: 150vh; /* Ensure it takes the full height if needed */
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
`;

