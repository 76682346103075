// SidebarContext.js
import React, { createContext, useState } from 'react';

// Create the Context
export const SidebarContext = createContext();

// Create a provider component
export const SidebarProvider = ({ children }) => {
  const [isSidebarExpanded, setSidebarExpanded] = useState(true);

  // Toggle function for sidebar
  const toggleSidebar = () => {
    setSidebarExpanded(prev => !prev);
  };

  return (
    <SidebarContext.Provider value={{ isSidebarExpanded, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
