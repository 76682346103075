import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore'; // Firestore imports
import { db } from './firebase'; // Firebase config file
import Layout from './Layout';
import Post from './Post'; // Post component to display individual posts
import { useLocation } from 'react-router-dom'; // For getting the query parameters


function Search() {
  const [posts, setPosts] = useState([]); // List of posts to display
  const [loading, setLoading] = useState(true); // Loading state
  const [filteredPosts, setFilteredPosts] = useState([]); // Filtered posts based on keywords
  const location = useLocation(); // Get the query parameters from the URL
  const [keywords, setKeywords] = useState([]); // List of keywords from the query
  const [subjects, setSubjects] = useState({}); // Mapping of subjectID to subject name

  // Fetch all posts from Firestore and their respective owner/user data
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        // Fetch posts
        const postsSnapshot = await getDocs(collection(db, 'posts'));
        const postsData = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        // Get all unique ownerUIDs, filter out any that are undefined or null
        const ownerUIDs = [...new Set(postsData.map(post => post.ownerUID).filter(uid => uid))];
  
        if (ownerUIDs.length === 0) {
          setLoading(false);
          return; // If there are no valid ownerUIDs, exit early
        }
  
        // Fetch all users in a single query using their UIDs
        const userDocs = await Promise.all(ownerUIDs.map(uid => getDoc(doc(db, 'users', uid))));
  
        // Create a mapping of UID to user data
        const usersMap = userDocs.reduce((acc, userDoc) => {
          if (userDoc.exists()) {
            acc[userDoc.id] = userDoc.data();
          }
          return acc;
        }, {});
  
        // Attach user data to posts, filter out posts with missing user data
        const postsWithUser = postsData.map(post => ({
          ...post,
          user: usersMap[post.ownerUID] || null, // Use the user data from the map, or null if not found
        })).filter(post => post.user); // Filter out posts with no user data
  
        setPosts(postsWithUser); // Set the posts along with user data
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    };

    const fetchSubjects = async () => {
      try {
        const subjectsSnapshot = await getDocs(collection(db, 'kurser')); // Fetch all subjects from Firestore
        const subjectsMap = {};
        subjectsSnapshot.docs.forEach(doc => {
          subjectsMap[doc.id] = doc.data().name; // Create a mapping of subjectID to subject name
        });
        setSubjects(subjectsMap); // Set the subjects map
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchPosts();
    fetchSubjects(); // Fetch subjects on component mount
  }, []);

  // Extract keywords from URL query parameters when the component mounts
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const keywordsFromQuery = searchParams.getAll('keyword'); // This will give all 'keyword' parameters
  
    if (keywordsFromQuery.length > 0) {
      setKeywords(keywordsFromQuery); // Set the array of keywords
    } else {
      setKeywords([]); // If no keywords, set an empty array
    }
  }, [location.search]);

  // Filter posts based on the keywords
  useEffect(() => {
    if (keywords.length > 0 && posts.length > 0) {
      const filtered = posts.filter(post => {
        // Ensure all keywords match at least one field in the post
        return keywords.every(keyword => {
          const lowerKeyword = keyword.toLowerCase();

          // Check if the keyword matches post title, description, owner name, subject, or school
          const titleMatches = post.title && post.title.toLowerCase().includes(lowerKeyword);
          const descriptionMatches = post.description && post.description.toLowerCase().includes(lowerKeyword);
          const userMatches = post.user && post.user.fullName && post.user.fullName.toLowerCase().includes(lowerKeyword);
          const subjectMatches = subjects[post.subjectID] && subjects[post.subjectID].toLowerCase().includes(lowerKeyword); // Check subject name
          const schoolMatches = post.user && post.user.currentSchool && post.user.currentSchool.toLowerCase().includes(lowerKeyword);

          // Return true if the keyword matches any of the fields
          return titleMatches || descriptionMatches || userMatches || subjectMatches || schoolMatches;
        });
      });

      setFilteredPosts(filtered);
    }
  }, [keywords, posts, subjects]);

  return (
    <Layout>
      <div style={styles.searchContainer}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          filteredPosts.length > 0 ? (
            filteredPosts.map((post) => (
              <div style={styles.postStyle}>
                <Post key={post.id} postId={post.id} post={post} />
              </div>
            ))
          ) : (
            <p style={{ textAlign: 'center' }}>Inget matchar din sökning.</p>
          )
        )}
      </div>
    </Layout>
  );  
  
}

const styles = {
  postStyle: {
    margin: '0', // Adjust this value as needed
  },
  searchContainer: {
    padding: '40px',
  },
};

export default Search;

