import React, { useEffect, useState, useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarContext } from './SidebarContext'; // Import the SidebarContext
import CreatePostModal from './CreatePostModal'; // Import the modal
import { getProfilePicture } from './utils/getProfilePicture'; // Adjust path as necessary

function Layout({ children }) {
  const { isSidebarExpanded, toggleSidebar } = useContext(SidebarContext); // Access sidebar state and toggle function
  const [profilePicUrl, setProfilePicUrl] = useState(''); // Initialize empty, will be set later
  const [searchInput, setSearchInput] = useState(''); // For search bar input
  const [keywords, setKeywords] = useState([]); // List of keywords
  const [showCreatePostModal, setShowCreatePostModal] = useState(false); // Modal state
  const [isLibraryHovered, setIsLibraryHovered] = useState(false); // Track hover state for Library icon
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  // Assuming user info is available globally (or fetched), you can use a user object like this:
  const user = { 
    photoURL: null, // Change this to simulate users with or without profile picture
    name: 'John Doe',
  };

  // Fetch user profile picture when component mounts or user changes
  useEffect(() => {
    const profileUrl = getProfilePicture(user); // Use utility function to set profile picture
    setProfilePicUrl(profileUrl);
  }, [user]);

  // Check for query parameters when the component mounts or location changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const keywordsFromQuery = searchParams.getAll('keyword');

    if (keywordsFromQuery.length > 0) {
      setKeywords(keywordsFromQuery);
    } else {
      setKeywords([]);
    }
  }, [location.search]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const trimmedInput = searchInput.trim(); // Trim input to avoid extra spaces
    if (trimmedInput && !keywords.includes(trimmedInput)) {
      if (keywords.length >= 5) {
        alert('Du kan bara använda upp till 5 sökord.');
        return;
      }
      const updatedKeywords = [...keywords, trimmedInput];
      const keywordParams = updatedKeywords.map(kw => `keyword=${encodeURIComponent(kw)}`).join('&');
      navigate(`/search?${keywordParams}`);
      setSearchInput(''); // Clear input after search
    }
  };

  const removeKeyword = (keywordToRemove) => {
    const updatedKeywords = keywords.filter((kw) => kw !== keywordToRemove);
    setKeywords(updatedKeywords);

    if (updatedKeywords.length > 0) {
      const keywordParams = updatedKeywords.map(kw => `keyword=${encodeURIComponent(kw)}`).join('&');
      navigate(`/search?${keywordParams}`);
    } else {
      navigate('/login'); // Navigate to homepage if no keywords remain
    }
  };

  // Toggle the modal state (open or close)
  const toggleCreatePostModal = () => {
    setShowCreatePostModal((prev) => !prev);
  };

  return (
    <Container>
      <TopBar>
        <HamburgerButton onClick={toggleSidebar}>
          <span></span>
          <span></span>
          <span></span>
        </HamburgerButton>
        <TitleSrc href="/Homepage">
          <LogoHeaderContainer>
            <Logo src="/teachrlogotype.png" alt="Logo" />
            <Header>Teachr</Header>
          </LogoHeaderContainer>
        </TitleSrc>


        <SearchKeywordsContainer>
          <SearchForm onSubmit={handleSearchSubmit}>
            <SearchInput
              type="text"
              placeholder="Sök bland allt"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </SearchForm>

          {/* Display the keywords with a black cross for removal */}
          {keywords.length > 0 && (
            <KeywordsDisplay>
              {keywords.map((kw, index) => (
                <Keyword key={index}>
                  {kw}
                  <CloseButton onClick={() => removeKeyword(kw)}>✖</CloseButton>
                </Keyword>
              ))}
            </KeywordsDisplay>
          )}
        </SearchKeywordsContainer>

        <CreatePostButton onClick={toggleCreatePostModal}>Starta inlägg</CreatePostButton>
        <ProfilePic src={profilePicUrl} alt="Profile" onClick={() => handleNavigation('/myprofile')} />
      </TopBar>

      {/* Modal Component */}
      <CreatePostModal showModal={showCreatePostModal} closeModal={toggleCreatePostModal} />

      <Content>
        <Sidebar expanded={isSidebarExpanded}>
          {/* SidebarMenuItems Wrapper */}
          <SidebarMenuItems>
            {/* Home Icon Item */}
            <SidebarMenuItem
              expanded={isSidebarExpanded}
              isActive={location.pathname === '/Homepage'} // Check if the path is active
              onClick={(e) => {
                e.stopPropagation(); // Prevents any event that could toggle the sidebar
                handleNavigation('/Homepage');
              }}
            >
              <HomeIcon src="/home.png" alt="Home Icon" />
              <span className="text">Startsida</span>
            </SidebarMenuItem>

            {/* Library Icon Item */}
            <SidebarMenuItem
              expanded={isSidebarExpanded}
              isActive={location.pathname === '/Courses'} // Check if the path is active
              onMouseEnter={() => setIsLibraryHovered(true)} // Set hover state on mouse enter
              onMouseLeave={() => setIsLibraryHovered(false)} // Reset hover state on mouse leave
              onClick={(e) => {
                e.stopPropagation();
                handleNavigation('/Courses');
              }}
            >
              <LibraryIcon src="/library.png" alt="Library Icon" isHovered={isLibraryHovered} />
              <span className="text">Bibliotek</span>
            </SidebarMenuItem>

            {/* Profile Icon Item */}
            <SidebarMenuItem
              expanded={isSidebarExpanded}
              isActive={location.pathname === '/myprofile'} // Check if the path is active
              onClick={(e) => {
                e.stopPropagation();
                handleNavigation('/myprofile');
              }}
            >
              <ProfileIcon src="/myprofile.png" alt="Profile Icon" />
              <span className="text">Min profil</span>
            </SidebarMenuItem>

            {/* Divider */}
            <hr style={{ border: 'none', borderBottom: '1px solid #ddd' }} />

            {/* Log Out (Settings) Icon Item */}
            <SidebarMenuItem
              expanded={isSidebarExpanded}
              isActive={location.pathname === '/settings'} // Check if the path is active
              onClick={(e) => {
                e.stopPropagation();
                handleNavigation('/settings');
              }}
            >
              <SettingsIcon src="/settings.png" alt="Settings Icon" />
              <span className="text">Inställningar</span>
            </SidebarMenuItem>
          </SidebarMenuItems>
        </Sidebar>

        <MainContent expanded={isSidebarExpanded}>
          {children}
        </MainContent>
      </Content>
    </Container>
  );
}

// Styled-components (no changes needed here)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding:
    10px 20px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #ddd;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100vw;
  height: 50px;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
  margin-top: 60px;
  overflow-y: auto;
  width: 100%; /* Ensure that it takes up the full width */
  box-sizing: border-box; /* Ensure padding doesn't affect layout */
`;

const SearchKeywordsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const LogoHeaderContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`;

const Header = styled.h1`
  display: flex;
  font-size: 24px;
  color: #000;
  cursor: pointer;
  margin: 0;
  text-decoration: none; /* No underline by default */

  &:hover {
    color: #0077b6;
    text-decoration: underline; /* Underline only on hover */
  }
`;



const TitleSrc = styled.a`
  text-decoration: none; /* Ensures no underline by default */
  color: inherit; /* Makes sure the text inherits the parent's color */
  &:hover {
    text-decoration: none; /* Ensures no underline on hover for the anchor tag itself */
  }
`;



const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 5px;
  font-weight: normal;
`;

const SearchForm = styled.form`
  flex: 1;
  max-width: 150px;
  margin-left: 18px;
`;

const SearchInput = styled.input`
  display: inline-block;
  width: 150px; 
  height: 25px;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  outline: none;
  font-size: 14px;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const CreatePostButton = styled.button`
  background-color: #FFFFFF;
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: auto;
  margin-right: 100px;
  border: 1px solid #5F6367;
  
  &:hover {
    background-color: #F0F0F0;
  }
`;

const HamburgerButton = styled.div`
  padding: 12px;
  margin-right: 15px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;

  &:hover {
    background-color: #F0F0F0;
  }

  & > span {
    display: block;
    height: 2px;
    width: 18px;
    background-color: #5F6368;
    margin: 3px 0;
    transition: all 0.3s ease;
  }
`;

const ProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  &:hover {
    background-color: #F0F0F0;
  }
  margin-right: 50px;
`;

const Sidebar = styled.div`
  width: ${(props) => (props.expanded ? '250px' : '80px')};
  background-color: #FFFFFF;
  transition: width 0.3s ease;
  border-right: 1px solid #ddd;
  position: fixed; 
  left: 0;
  top: 70px;  /* Set top relative to TopBar's height */
  height: calc(100vh - 6vh);  /* Adjust height to fill the remaining viewport under the TopBar */
  z-index: 99;
`;


const SidebarMenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  /* Always have the right border rounded */
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;

  background-color: ${(props) => (props.isActive ? '#D1E9F6' : 'transparent')};

  &:hover {
    background-color: #F0F0F0;
  }

  span.text {
    opacity: ${(props) => (props.expanded ? 1 : 0)}; 
    visibility: ${(props) => (props.expanded ? 'visible' : 'hidden')};
    transition: opacity 0.3s ease, visibility 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 10px;
  }
`;

const SidebarMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-right: 12px;
`;

const HomeIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 7px;
`;

const LibraryIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 7px;
`;

const ProfileIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 7px;
`;

const SettingsIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 7px;
`;

const KeywordsDisplay = styled.div`
  display: flex;
  align-items: center;
  margin-left: 35px;
`;

const Keyword = styled.span`
  background-color: #E0E0E0;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.span`
  margin-left: 8px;
  cursor: pointer;
  color: black;
  font-weight: bold;

  &:hover {
    color: red;
  }
`;

export default Layout;
