import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from './firebase'; // Your Firebase config
import styled from 'styled-components';

const LinkPreview = ({ url }) => {
  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchMetadata = async () => {
      const functions = getFunctions(app);
      const getMetadata = httpsCallable(functions, 'getMetadata');

      try {
        const result = await getMetadata({ url });
        setMetadata(result.data);
      } catch (error) {
        console.error('Error fetching metadata:', error);
        setError(true);
      }
    };

    fetchMetadata();
  }, [url]);

  // Destructure metadata, or fall back to empty values
  const { title, image } = metadata || {};

  // If title is not available, use the URL as the title
  const displayTitle = title && title !== 'No title available' ? title : url;

  // Only show the link in place of the description if there is a title
  const showLinkAsDescription = !title || title === 'No title available';

  const displayImage = image || null;

  return (
    <PreviewContainer href={url} target="_blank" rel="noopener noreferrer">
      <ImageContainer>
        {displayImage ? (
          <Image src={displayImage} alt={displayTitle} />
        ) : (
          <PlaceholderImage />
        )}
      </ImageContainer>
      <ContentContainer>
        <Title>{displayTitle}</Title>
        {/* Show the URL as the description only if there is a title */}
        {showLinkAsDescription ? null : <Description>{url}</Description>}
      </ContentContainer>
    </PreviewContainer>
  );
};

export default LinkPreview;

// Styled-components

const Title = styled.h4`
  margin: 12px 0 10px 15px;
  max-height: 1.2em;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
`;

const PreviewContainer = styled.a`
  display: flex;

  height: 70px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
  overflow: hidden;
  cursor: pointer;

  &:hover ${Title} { /* Applying hover effect to the Title when PreviewContainer is hovered */
    color: #0077b6;
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 70px;
  height: 70px;
  background-color: #f0f0f0;
`;

const Image = styled.img`
  width: 100px;
  height: 70px;
  object-fit: cover;
`;

const PlaceholderImage = styled.div`
  width: 100px;
  height: 70px;
  background-color: #e0e0e0; /* Gray background for missing images */
`;

const ContentContainer = styled.div`
  padding: 0px;
  flex: 1;
  overflow: hidden;
`;

const Description = styled.p`
  margin: 0 0 0 15px;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
  max-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
`;
