import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function Register() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [yearsOfTeaching, setYearsOfTeaching] = useState('');
  const [fullName, setFullName] = useState('');
  const [currentSchool, setCurrentSchool] = useState('');
  const [fillLevel, setFillLevel] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const totalFields = 4;
  const fieldsFilledCount = [fullName, currentSchool, yearsOfTeaching, selectedOption].filter(Boolean).length;

  useEffect(() => {
    setFillLevel(fieldsFilledCount * (100 / totalFields));
  }, [fieldsFilledCount]);

  const handleOptionClick = (option) => {
    if (option === 'Grundskola') {
      setSelectedOption(1);
    } else if (option === 'Gymnasieskola') {
      setSelectedOption(2);
    } else {
      setSelectedOption('');
    }
    setSelectedElement(null);
  };

  const handleYearsChange = (e) => {
    let value = parseInt(e.target.value, 10);

    if (isNaN(value) || value < 0) {
      value = 0;
    } else if (value > 80) {
      value = 80;
    }

    setYearsOfTeaching(value.toString());
  };

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleCurrentSchoolChange = (e) => {
    setCurrentSchool(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!fullName || !currentSchool || !yearsOfTeaching || !selectedOption) {
      setErrorMessage('Alla fält måste fyllas i');
    } else {
      setErrorMessage('');
      navigate('/register2', { state: { fullName, currentSchool, selectedOption, yearsOfTeaching } });
    }
  };

  return (
    <Container>
      <FormContainer>
        <LogoContainer>
          <LogoImage src="/teachrlogotype.png" alt="Teachr Logo" />
          <LogoText>Teachr</LogoText>
        </LogoContainer>

        <Form onSubmit={handleSubmit}>
          <Heading>Skapa ett konto</Heading>
          <FormContentContainer>
            <FormGroup>
              <Input
                type="text"
                placeholder="Namn och efternamn*"
                selected={selectedElement === 'fullName'}
                value={fullName}
                onChange={handleFullNameChange}
                onFocus={() => setSelectedElement('fullName')}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                placeholder="Nuvarande skola*"
                selected={selectedElement === 'currentSchool'}
                value={currentSchool}
                onChange={handleCurrentSchoolChange}
                onFocus={() => setSelectedElement('currentSchool')}
              />
            </FormGroup>

            <FormGroup>
              <OptionsContainer>
                <Option
                  onClick={() => handleOptionClick('Grundskola')}
                  selected={selectedOption === 1}
                >
                  <OptionImage src="/grundskolanlogo.png" alt="Grundskola" />
                  <OptionText>Grundskola</OptionText>
                </Option>
                <Option
                  onClick={() => handleOptionClick('Gymnasieskola')}
                  selected={selectedOption === 2}
                >
                  <OptionImage src="/gymnasietlogo.png" alt="Gymnasium" />
                  <OptionText>Gymnasieskola</OptionText>
                </Option>
              </OptionsContainer>
            </FormGroup>

            <InlineFormGroup>
              <Label>Jag har varit lärare i</Label>
              <SmallInput
                type="number"
                value={yearsOfTeaching}
                onChange={handleYearsChange}
                selected={selectedElement === 'yearsOfTeaching'}
                onFocus={() => setSelectedElement('yearsOfTeaching')}
              />
              <span>år.</span>
            </InlineFormGroup>

            <Continue>
              <Square
                fillLevel={fillLevel}
              ></Square>
              <Square></Square>
              <Square></Square>
              <NextButton type="submit">Nästa</NextButton>
            </Continue>
          </FormContentContainer>
        </Form>
      </FormContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
    
  );
}

// Styled-components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: -120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  pointer-events: none;
`;

const LogoImage = styled.img`
  width: 52px;
  height: 52px;
`;

const LogoText = styled.p`
  font-size: 52px;
  font-weight: bold;
  color: #000;
`;

const Form = styled.form`
  width: 350px;
  height: 450px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  font-size: 26px;
`;

const FormContentContainer = styled.div`
  width: 65%;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 13px;
  ${(props) =>
    props.selected &&
    `
    border: 1px solid #0077b6;
    outline: none;
  `}

  &:hover {
    background-color: #F0F0F0;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

const Option = styled.div`
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: ${(props) => (props.selected ? '1px solid #0077b6' : '1px solid #ccc')};
  width: 48%;
  height: 110px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background-color: #ffffff;

  ${(props) =>
    props.selected &&
    `
    outline: 1px #0077b6;
  `}

  &:hover {
    background-color: #F0F0F0;
  }
`;


const OptionImage = styled.img`
  width: 110px;
  height: 110px;
  object-fit: cover;
  border-radius: 4px;
`;

const OptionText = styled.p`
  position: absolute;
  display: inline-flex;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #ffffff;
  padding: 5px;
  margin-bottom: 2px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  max-width: 100%;
  white-space: nowrap;
  border-radius: 4px;
`;

const InlineFormGroup = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  border: 1px solid #ccc;
  padding: 10px;
  width: 91%;
  border-radius: 4px;
`;

const Label = styled.label`
  margin-right: 5px;
`;

const SmallInput = styled.input`
  width: 40px;
  padding: 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 4px;
  margin-right: 4px;
  border: 1px solid #ccc;
  text-align: center;
  ${(props) =>
    props.selected &&
    `
    border: 1px solid #0077b6;
    outline: none;
  `}

  &:hover {
    background-color: #F0F0F0;
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const Continue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20.5px;
`;

const Square = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  margin-left: 8px;
  background: ${(props) =>
    props.fillLevel
      ? `linear-gradient(to top, #0077b6 ${props.fillLevel}%, #fff ${props.fillLevel}%)`
      : '#fff'};
  transition: background 1s cubic-bezier(0.42, 0, 0.58, 1);
`;

const NextButton = styled.button`
  width: 50%;
  padding: 10px;
  background-color: #0077b6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 25px;

  &:hover {
    background-color: #005f8a;
  }
`;

export default Register;
