import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // To fetch the postId from the URL
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from './firebase';
import Layout from './Layout';
import Post from './Post';
import { getProfilePicture } from './utils/getProfilePicture';
import styled from 'styled-components';

function PostPage() {
  const { postId } = useParams(); // Get the postId from the route parameters
  const { currentUser } = useAuth();
  const [post, setPost] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [commentAuthors, setCommentAuthors] = useState({}); // Store comment authors' names
  const [replyText, setReplyText] = useState({}); // Store reply text for each comment
  const [replyingTo, setReplyingTo] = useState(null); // Track which comment is being replied to

  useEffect(() => {
    const fetchPostData = async () => {
      const postDoc = await getDoc(doc(db, 'posts', postId));
      if (postDoc.exists()) {
        const postData = postDoc.data();
        setPost(postData);
        setComments(postData.comments || []);

        // Fetch the full names for comment owners
        const authors = {};
        for (const comment of postData.comments || []) {
          if (!authors[comment.ownerID]) {
            const userDoc = await getDoc(doc(db, 'users', comment.ownerID));
            if (userDoc.exists()) {
              authors[comment.ownerID] = userDoc.data().fullName || 'Unknown User';
            }
          }
        }
        setCommentAuthors(authors);
      }
    };

    fetchPostData();
  }, [postId]);

  const handleCommentPublish = async () => {
    if (commentText.trim()) {
      const newComment = {
        ownerID: currentUser.uid,
        commentText: commentText,
        date: new Date(),
        replies: [], // Initialize with an empty array for replies
      };

      try {
        const postRef = doc(db, 'posts', postId);
        await updateDoc(postRef, {
          comments: arrayUnion(newComment),
        });

        // Update the local state with the new comment
        setComments((prevComments) => [...prevComments, newComment]);
        setCommentText(''); // Clear the input field
      } catch (error) {
        console.error('Error publishing comment:', error);
      }
    }
  };

  const handleReplyPublish = async (commentIndex) => {
    if (replyText[commentIndex]?.trim()) {
      const newReply = {
        ownerID: currentUser.uid,
        commentText: replyText[commentIndex],
        date: new Date(),
      };

      try {
        const postRef = doc(db, 'posts', postId);
        const updatedComments = [...comments];
        updatedComments[commentIndex].replies.push(newReply);
        await updateDoc(postRef, { comments: updatedComments });

        // Update the local state with the new reply
        setComments(updatedComments);
        setReplyText({ ...replyText, [commentIndex]: '' }); // Clear the reply input
        setReplyingTo(null); // Close reply section
      } catch (error) {
        console.error('Error publishing reply:', error);
      }
    }
  };

  return (
    <Layout>
      <div>
        <Post postId={postId} /> {/* Display the post content */}
        
        {/* Comment creation section */}
        {currentUser && (
          <CommentContainer>
            <UserProfilePic src={getProfilePicture(currentUser)} />
            <CommentInput
              placeholder="Lägg till kommentar"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <SendButtonContainer onClick={handleCommentPublish}>
              <SendImage src="/send.png" alt="Send" />
            </SendButtonContainer>
          </CommentContainer>
        )}

        {/* Display comments */}
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <div key={index}>
              <CommentContainer>
                <UserProfilePic src={getProfilePicture(comment.ownerID)} />
                <TextDisplay>
                  <OwnerUID>{commentAuthors[comment.ownerID] || comment.ownerID}</OwnerUID> {/* Show user's name */}
                  <CommentText>{comment.commentText}</CommentText>
                </TextDisplay>
                <RespondImage
                  src="/respond.png"
                  alt="Respond"
                  onClick={() => setReplyingTo(replyingTo === index ? null : index)} // Toggle reply section
                />
              </CommentContainer>

              {/* Display replies */}
              {comment.replies.map((reply, replyIndex) => (
                <RespondCommentContainer key={replyIndex}>
                  <UserProfilePic src={getProfilePicture(reply.ownerID)} />
                  <TextDisplay>
                    <OwnerUID>{commentAuthors[reply.ownerID] || reply.ownerID}</OwnerUID>
                    <CommentText>{reply.commentText}</CommentText>
                  </TextDisplay>
                </RespondCommentContainer>
              ))}

              {/* Reply input section */}
              {replyingTo === index && (
                <RespondCommentContainer>
                  <UserProfilePic src={getProfilePicture(currentUser)} />
                  <CommentInput
                    placeholder="Skriv ett svar"
                    value={replyText[index] || ''}
                    onChange={(e) =>
                      setReplyText({ ...replyText, [index]: e.target.value })
                    }
                  />
                  <SendButtonContainer onClick={() => handleReplyPublish(index)}>
                    <SendImage src="/send.png" alt="Send" />
                  </SendButtonContainer>
                </RespondCommentContainer>
              )}
            </div>
          ))
        ) : (
          <NoCommentsText>Inga kommentarer än.</NoCommentsText>
        )}
      </div>
    </Layout>
  );
}

const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 670px;
  margin: 20px auto 0 auto;
  background-color: #fff;
`;

const RespondCommentContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 550px;
  margin: 0px auto;
  background-color: #fff;
`;

const UserProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const CommentInput = styled.input`
  flex-grow: 1;
  border: none;
  width: 50%;
  border-radius: 9999px;
  padding: 10px 16px;
  background-color: #F0F0F0;
  outline: none;
`;

const SendButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SendImage = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const TextDisplay = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  width: 85%;
`;

const OwnerUID = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
  color: #333;
`;

const CommentText = styled.span`
  font-size: 14px;
  color: #666;
  word-wrap: break-word;
`;

const NoCommentsText = styled.p`
  text-align: center;
  font-size: 16px;
  color: #888;
  margin-top: 20px;
`;

const RespondImage = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: auto;
  border-radius: 50%;
  padding: 5px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export default PostPage;
