import React, { useState, useEffect, useRef } from 'react';
import Layout from './Layout';
import Post from './Post';
import { getDocs, collection, query, orderBy, startAfter, limit, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { useAuth } from './AuthContext';
import EndPost from './EndPost'

function HomePage() {
  const { currentUser } = useAuth();
  const [posts, setPosts] = useState([]); // Stores the posts to be displayed
  const [lastDoc, setLastDoc] = useState(null); // Keeps track of the last document fetched for pagination
  const [loading, setLoading] = useState(false); // Loading state to show "Loading more posts..."
  const [hasMore, setHasMore] = useState(true); // Flag to check if there are more posts to load
  const [starredPosts, setStarredPosts] = useState([]); // Stores user's starred posts
  const [userSubjects, setUserSubjects] = useState([]); // Stores the subjects the user is subscribed to
  const observer = useRef(); // Intersection Observer for infinite scrolling
  const postSet = useRef(new Set()); // Set to track already loaded posts and avoid duplicates

  // Fetch user data (starred posts and subjects) on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) return;

      try {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setStarredPosts(userData.starredPosts || []); // Set user's starred posts
          setUserSubjects(userData.subjects || []); // Set user's subjects
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [currentUser]);

  // Fetch posts when userSubjects or lastDoc changes
  useEffect(() => {
    const fetchPosts = async () => {
      if (loading || !hasMore || userSubjects.length === 0) return; // Prevent fetching if no more posts or subjects

      setLoading(true); // Set loading to true when starting to fetch

      try {
        // Query Firestore for posts ordered by title and paginated with limit of 5
        const postsQuery = query(
          collection(db, 'posts'),
          orderBy('title'),
          ...(lastDoc ? [startAfter(lastDoc)] : []),
          limit(5)
        );

        const postDocs = await getDocs(postsQuery);
        const fetchedPosts = postDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Filter posts based on user's subjects and avoid duplicates using postSet
        const filteredPosts = fetchedPosts.filter((post) => {
          const postSubjectID = post.subjectID || '';
          return userSubjects.includes(postSubjectID) && !postSet.current.has(post.id);
        });

        filteredPosts.forEach((post) => postSet.current.add(post.id)); // Add posts to the set to avoid duplicates

        // If less than 5 posts are fetched, it means there are no more posts to load
        if (filteredPosts.length < 5) {
          setHasMore(false);
        }

        setPosts((prevPosts) => [...prevPosts, ...filteredPosts]); // Append the new posts to the existing list
        setLastDoc(postDocs.docs[postDocs.docs.length - 1]); // Update the lastDoc for pagination
      } catch (error) {
        console.error('Error fetching posts:', error);
      }

      setLoading(false); // Set loading to false when fetching is done
    };

    fetchPosts();
  }, [lastDoc, loading, hasMore, userSubjects]);

  // Infinite scroll: Detect the last post and fetch more when it comes into view
  const lastPostElementRef = (node) => {
    if (loading) return; // Prevent multiple triggers during loading
    if (observer.current) observer.current.disconnect(); // Disconnect the previous observer
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setLoading(true); // Trigger loading more posts
      }
    });
    if (node) observer.current.observe(node); // Observe the last post
  };

  return (
    <Layout>
      <div style={styles.pageContainer}>
        {/* Loop through the posts and render them */}
        {posts.map((post, index) => {
          if (!post) return null;
          const uniqueKey = `${post.id}-${index}`;
          if (index === posts.length - 1) {
            // Attach lastPostElementRef to the last post for infinite scrolling
            return (
              <div ref={lastPostElementRef} key={uniqueKey} style={styles.postContainer}>
                <Post postId={post.id} />
              </div>
            );
          } else {
            return (
              <div key={uniqueKey} style={styles.postContainer}>
                <Post postId={post.id} />
              </div>
            );
          }
        })}
        {/* Loading state to show when fetching more posts */}
        {loading && <div></div>}
        {/* No posts message when no posts are available */}
        {!loading && posts.length === 0 && <div></div>}
        <EndPost></EndPost>
      </div>
    </Layout>
  );
}

const styles = {
  pageContainer: {
    padding: '20px',
  },
  postContainer: {
    marginBottom: '20px', // Adds space between posts
  },
};

export default HomePage;
