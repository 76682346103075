import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from './AuthContext'; // Import the AuthContext
import { db } from './firebase'; // Import firebase database
import { addDoc, collection } from "firebase/firestore"; // Import Firestore functions

const FeedbackWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null); // Tracks contact method
  const [feedback, setFeedback] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [contactDetail, setContactDetail] = useState(''); // New state for phone or email
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  const { currentUser } = useAuth(); // Get the current user from AuthContext

  const toggleWidget = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!feedback) {
      setErrorMessage('Skriv vad du vill att vi ska skapa.');
      return;
    }

    if (!selectedContact) {
      setErrorMessage('Var god välj hur jag ska kontakta dig.');
      return;
    }

    let feedbackData = {
      comment: feedback,
      telephone: '',
      email: '',
    };

    if (selectedContact === 'email') {
      if (currentUser) {
        feedbackData.email = currentUser.email; // Use logged-in user's email
      } else {
        if (!contactDetail) {
          setErrorMessage('Skriv din e-postadress.');
          return;
        }
        feedbackData.email = contactDetail; // Use entered email if not logged in
      }
    }

    if (selectedContact === 'phone') {
      if (!contactDetail) {
        setErrorMessage('Skriv in ditt telfonnummer.');
        return;
      }
      feedbackData.telephone = contactDetail; // Use entered phone number
      if (currentUser) {
        feedbackData.email = currentUser.email; // Attach user's email if logged in
      }
    }

    // Save the feedback in the 'feedback' collection in Firebase
    try {
      await addDoc(collection(db, 'feedback'), feedbackData);
      setErrorMessage(''); // Clear error
      setFeedback(''); // Clear feedback
      setContactDetail(''); // Clear contact detail
      alert('Tack för att du hjälper oss att förbättra! Grundaren kommer att kontakta dig snart.');
    } catch (error) {
      console.error('Error saving feedback: ', error);
      setErrorMessage('Error submitting feedback. Please try again.');
    }
  };

  // Clear error message when contact method changes
  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(''); // Clear error message when contact method changes
    }
  }, [selectedContact]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  return (
    <> 
      <WidgetContainer>
        <WidgetHeader onClick={toggleWidget}>
          <span>Beskriv vad du vill att vi skapar</span>
          <CloseButton onClick={toggleWidget}>&times;</CloseButton>
        </WidgetHeader>
        <ExpandableContent
          isOpen={isOpen}
          contentHeight={contentHeight}
          ref={contentRef}
        >
          <form onSubmit={handleSubmit}>
            <FeedbackTextArea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Det har aldrig tagit oss mer än en vecka att koda en ny funktion."
            />

            <ContactDetails>
              <HowToContactUser>Hur ska grundaren kontakta dig?</HowToContactUser>
              <ButtonContainer>
                <CubeButtonWrapper
                  selected={selectedContact === 'email'}
                  onClick={() => setSelectedContact('email')}
                >
                  <CubeButton>
                    <img src="/email.png" alt="Email" style={{ width: '25px', height: '25px' }} />
                  </CubeButton>
                  <span>E-post</span>
                </CubeButtonWrapper>

                <CubeButtonWrapper
                  selected={selectedContact === 'phone'}
                  onClick={() => setSelectedContact('phone')}
                >
                  <CubeButton>
                    <img src="/phone.png" alt="Phone" style={{ width: '25px', height: '25px' }} />
                  </CubeButton>
                  <span>Telefon</span>
                </CubeButtonWrapper>
              </ButtonContainer>

              {selectedContact === 'phone' || (!currentUser && selectedContact === 'email') ? (
                <input
                  type="text"
                  value={contactDetail}
                  onChange={(e) => setContactDetail(e.target.value)}
                  placeholder={selectedContact === 'phone' ? 'Skriv ditt nummer här' : 'Enter email'}
                  style={{ width: '93%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
                />
              ) : null}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </ContactDetails>

            <SubmitButton type="submit">Skicka</SubmitButton>
            
          </form>
        </ExpandableContent>
      </WidgetContainer>
    </>
  );
};

export default FeedbackWidget;



// Styled-components

const WidgetContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 310px; /* Ensure the width stays consistent */
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden; /* Prevent content from shifting during transition */
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px; /* Space between the email and phone button sections */
  justify-content: space-between; /* Ensure equal spacing between buttons */
  width: 100%; /* Keep the button container's width consistent */
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #fff;
  color: black;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
  align-self: flex-end; /* Aligns the button to the end */
  width: 100%; /* Set button width to 100% to avoid stretching */
  max-width: 350px; /* Ensure it doesn't stretch beyond container width */

  &:hover {
    background-color: #f0f0f0;
  }
`;

const WidgetHeader = styled.div`
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: black;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 1001;

`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: black;
  font-size: 1.5em;
  cursor: pointer;
`;

const ExpandableContent = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? `${props.contentHeight}px` : '0')}; 
  transition: max-height 0.5s ease;
  padding: ${(props) => (props.isOpen ? '20px' : '0')}; /* Padding only when open */
  padding-top: 0; /* Remove padding top when closed */
  height: auto;
`;

const FeedbackTextArea = styled.textarea`
  width: 92.5%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  font-size: 12px;
  font-family: 'Pier-sans', sans-serif;
`;

const ContactDetails = styled.div`
  border-radius: 8px;
`;

const HowToContactUser = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
`;

const CubeButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 8px;
  padding-right: 10px;
  margin-bottom: 10px;
  background-color: ${({ selected }) => (selected ? '#D1E9F6' : '#fff')};
  cursor: pointer;

  &:hover {
    background-color: #F0F0F0;
  }

  span {
    color: black;
  }
`;

const CubeButton = styled.button`
  width: 45px;
  height: 45px;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9em;
  margin: 10px 0 0 0;
`;
