import React, { useState, useEffect, useRef } from 'react';
import { getDoc, doc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { db } from './firebase';
import styled, { createGlobalStyle } from 'styled-components';

const MAX_FILES = 10;
const MAX_TOTAL_SIZE_MB = 100;

// Import Open Sans font from Google Fonts
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
  
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  input, textarea, button {
    font-family: 'Open Sans', sans-serif;
  }
`;

function CreatePost() {
  const { postId } = useParams();
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subjectID, setSubjectID] = useState('');
  const [textbook, setTextbook] = useState('');
  const [materialType, setMaterialType] = useState([]);
  const [links, setLinks] = useState(['']); // Initialize with one empty link
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const storage = getStorage();
  const fileInputRef = useRef();

  useEffect(() => {
    if (currentUser && postId && location.pathname.startsWith('/createpost')) {
      const fetchData = async () => {
        const postDoc = await getDoc(doc(db, 'posts', postId));
        if (postDoc.exists()) {
          const data = postDoc.data();
          setTitle(data.title);
          setDescription(data.description);
          setSubjectID(data.subjectID);
          setTextbook(data.textbook || '');
          setMaterialType(data.materialType || []);
          
          const loadedLinks = data.links || [''];
          const filledLinks = loadedLinks.filter(link => link.trim() !== '');
          setLinks([...filledLinks, '']);  // Ensure one empty field
          setUploadedFiles(data.files || []);
        }
      };
      fetchData();
    }

    const fetchUserSubjects = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const subjectIds = userDoc.data().subjects || [];
          const fetchedSubjects = await Promise.all(
            subjectIds.map(async (subjectId) => {
              const subjectDoc = await getDoc(doc(db, 'kurser', subjectId));
              return { id: subjectId, name: subjectDoc.data()?.name || 'Unknown Subject' };
            })
          );
          setSubjects(fetchedSubjects);
        }
      }
    };

    fetchUserSubjects();
  }, [postId, currentUser, uploadedFiles]);

  const handleFileUpload = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (files.length + selectedFiles.length > MAX_FILES) {
      alert('You can only upload up to 10 files.');
      return;
    }

    const totalSizeMB = [...files, ...selectedFiles].reduce((acc, file) => acc + file.size / (1024 * 1024), 0);
    if (totalSizeMB > MAX_TOTAL_SIZE_MB) {
      alert('Total file size exceeds 100 MB.');
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const uploadFiles = async (postId) => {
    const uploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `posts/${subjectID}/${postId}/${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, file);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          null,
          reject,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({ name: file.name, url: downloadURL });
          }
        );
      });
    });
    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Turn off anonymous mode for the current user
    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), { Anonym: false });
      } catch (error) {
        console.error('Error updating user anonymity status:', error);
        return;
      }
    }
  
    // Navigate to different routes after submission
    if (location.pathname === '/myprofile') {
      navigate('/homepage', { replace: true });
    } else {
      navigate('/myprofile', { replace: true });
    };
  
    // Clean up the links and files
    const cleanedLinks = links.filter((link) => link.trim() !== '');
    const cleanedUploadedFiles = uploadedFiles.filter((file) => file.url);
  
    // Replace double newlines with paragraph tags, and single newlines with spaces
    const formattedDescription = description
      .split('\n\n') // Split by double newlines for paragraphs
      .map(paragraph => `<p>${paragraph.replace(/\n/g, ' ')}</p>`) // Replace single newlines with spaces
      .join('');
  
    // Prepare the post data to be saved in Firestore
    const postData = {
      title,
      description: formattedDescription,  // Save the formatted description
      subjectID,
      materialType,
      links: cleanedLinks,
      ownerUID: currentUser?.uid || null,
      textbook,
      files: cleanedUploadedFiles,
    };
  
    try {
      if (postId) {
        // If the post already exists, update it
        await updateDoc(doc(db, 'posts', postId), postData);
        const uploadedFileData = await uploadFiles(postId);
        await updateDoc(doc(db, 'posts', postId), { files: [...cleanedUploadedFiles, ...uploadedFileData] });
      } else {
        // Otherwise, create a new post
        const newPostRef = await addDoc(collection(db, 'posts'), postData);
        const uploadedFileData = await uploadFiles(newPostRef.id);
        await updateDoc(doc(db, 'posts', newPostRef.id), { files: uploadedFileData });
      }
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };



  const MAX_LINKS = 6; // Set maximum number of links

  const handleLinkChange = (e, index) => {
    const newLinks = [...links];
    newLinks[index] = e.target.value;
    setLinks(newLinks);

    // Ensure there's one empty link field at the end, up to MAX_LINKS
    const filledLinks = newLinks.filter(link => link.trim() !== '');
    if (filledLinks.length < MAX_LINKS && filledLinks.length === newLinks.length) {
      setLinks([...newLinks, '']);
    }
  };


  const handleUploadFromDrive = () => {
    alert('Upload from Drive is not implemented yet.');
  };

  const handleMaterialTypeSelection = (type) => {
    setMaterialType((prevTypes) =>
      prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]
    );
  };

  const removeUploadedFile = async (index) => {
    const file = uploadedFiles[index];
    const fileRef = ref(storage, file.url);

    try {
      await deleteObject(fileRef);
      const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
      setUploadedFiles(updatedFiles);

      if (postId) {
        await updateDoc(doc(db, 'posts', postId), { files: updatedFiles });
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Form onSubmit={handleSubmit}>
          {/* Title */}
          <FormGroup>
            <Input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Rubrik*"
                required
            />
          </FormGroup>

          {/* Description */}
          <FormGroup>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Beskrivning"
              required
            />
          </FormGroup>


          {/* Import from Google Drive and Local */}
          <FormGroupRow>
            <ImportButton type="button" onClick={handleUploadFromDrive}>
              Importera från Drive
              <img src="/drive.png" alt="Google Drive" />
            </ImportButton>

            <ImportButton type="button" onClick={() => fileInputRef.current.click()}>
              Importera <br />
              från dator
              <img src="/fromlocalfiles.png" alt="Computer" />
            </ImportButton>
            <HiddenFileInput type="file" multiple onChange={handleFileUpload} ref={fileInputRef} />
          </FormGroupRow>

          {/* Subject and Textbook Dropdowns */}
          <FormGroupRow>
            <SelectContainer>
              <SelectInput value={subjectID} onChange={(e) => setSubjectID(e.target.value)} required>
                <option value="" disabled>
                  Välj kursplan*
                </option>
                {subjects.map((subj) => (
                  <option key={subj.id} value={subj.id}>
                    {subj.name}
                  </option>
                ))}
              </SelectInput>
              <ArrowIcon src="/dropdownicon.png" alt="Dropdown arrow" />
            </SelectContainer>

            <SelectContainer>
              <SelectInput value={textbook} onChange={(e) => setTextbook(e.target.value)}>
                <option value="" disabled>
                  Välj kursbok
                </option>
              </SelectInput>
              <ArrowIcon src="/dropdownicon.png" alt="Dropdown arrow" />
            </SelectContainer>
          </FormGroupRow>

          {/* Material Type Buttons */}
          <PostTypeButtonGroup>
            <PostTypeButton
              type="button"
              onClick={() => handleMaterialTypeSelection('Presentation')}
              selected={materialType.includes('Presentation')}
            >
              <img src="/presentation.png" alt="Presentation" />
              <span>Presentation</span>
            </PostTypeButton>
            <PostTypeButton
              type="button"
              onClick={() => handleMaterialTypeSelection('Planering')}
              selected={materialType.includes('Planering')}
            >
              <img src="/lessonplan.png" alt="Lesson Plans" />
              <span>Plannering</span>
            </PostTypeButton>
            <PostTypeButton
              type="button"
              onClick={() => handleMaterialTypeSelection('Uppgift')}
              selected={materialType.includes('Uppgift')}
            >
              <img src="/assignment.png" alt="Assignment" />
              <span>Uppgift</span>
            </PostTypeButton>
          </PostTypeButtonGroup>

          {/* Links */}
          <FormGroup>
            {links.map((link, index) => (
              index % 2 === 0 && index < MAX_LINKS && ( // Ensure no more than 6 link fields
                <LinksRow key={index}>
                  <Input
                    type="url"
                    value={links[index]}
                    onChange={(e) => handleLinkChange(e, index)}
                    placeholder={`Länk ${index + 1}`}
                  />
                  {links[index + 1] !== undefined && (
                    <Input
                      type="url"
                      value={links[index + 1]}
                      onChange={(e) => handleLinkChange(e, index + 1)}
                      placeholder={`Länk ${index + 2}`}
                    />
                  )}
                </LinksRow>
              )
            ))}
          </FormGroup>




          {/* Selected Files */}
          {files.length > 0 && (
            <UploadedFilesContainer>
              <h3>Valda filer</h3>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <RemoveButton type="button" onClick={() => removeFile(index)}>
                      Ta bort
                    </RemoveButton>
                  </li>
                ))}
              </ul>
            </UploadedFilesContainer>
          )}

          {uploadedFiles.length > 0 && (
            <UploadedFilesContainer>
              <h3>Previously Uploaded Files</h3>
              <ul>
                {uploadedFiles.map((file, index) => (
                  <li key={index}>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                    <RemoveButton type="button" onClick={() => removeUploadedFile(index)}>
                      Remove
                    </RemoveButton>
                  </li>
                ))}
              </ul>
            </UploadedFilesContainer>
          )}

          {/* Submit Button */}
          <SubmitButton type="submit">{postId ? 'Uppdatera inlägg' : 'Skapa inlägg'}</SubmitButton>
        </Form>
      </Container>
    </>
  );
}

export default CreatePost;

// Styled Components
const Container = styled.div`
  width: 450px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 0 auto;
  max-height: 80vh;
  overflow-y: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const LinksRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px; /* Use the same gap here */
  
`;


const FormGroupRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px; /* Consistent gap between import buttons */
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 44%;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 95%;
  min-height: 100px;
  resize: vertical;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const RemoveButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #cc0000;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const ImportButton = styled.button`
  height: 60px;
  width: 47%;
  padding: 5px 30px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;

  img {
    width: 50px;
    height: 50px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

const UploadedFilesContainer = styled.div`
`;

const SubmitButton = styled.button`
  padding: 12px 20px;
  align-self: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;

  &:hover {
    background-color: #0056b3;
  }
`;

const PostTypeButton = styled.button`
  height: 120px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: ${(props) => (props.selected ? '#D3D3D3' : '#FFFFFF')};
  border: 1px solid #ccc;
  border-radius: 8px;

  img {
    width: 65px;
    height: 65px;
  }

  span {
    margin-top: 10px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

const PostTypeButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const SelectContainer = styled.div`
  position: relative;
  width: 47%;
`;

const SelectInput = styled.select`
  height: 60px;
  width: 100%;
  padding: 15px 20px;
  font-size: 16px;
  border-radius: 8px;
  appearance: none;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #ccc;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 20px;
  height: 20px;
`;
