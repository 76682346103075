import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Login from './Login';
import Register from './Register';  // Import the first step of the registration process
import Register2 from './Register2';  // Import the second step of the registration process
import Register3 from './Register3';  // Import the third step of the registration process
import HomePage from './HomePage';
import MyProfile from './MyProfile';
import CreatePost from './CreatePost';
import PostPage from './postVeiwer'; // The PostPage we just created
import Courses from './Courses';
import { AuthProvider } from './AuthContext'; // Import the AuthProvider
import UserProfile from './userProfile'; // Import the UserProfile component
import MyPosts from './myPosts'; // Import the MyPosts component
import Search from './search'; 
import Settings from './Settings';
import Landingpage from './landingpage';
import FeedbackWidget from './FeedbackWidget';  // Import the feedback widget

function App() {
  return (
    <Router>
      <AuthProvider>
        <MainApp />
      </AuthProvider>
    </Router>
  );
}

function MainApp() {
  const location = useLocation();

  return (
    <>
      {/* Conditionally render FeedbackWidget */}
      {location.pathname !== '/' && <FeedbackWidget />}

      <Routes>
        <Route path="/createpost" element={<CreatePost />} /> {/* For new post */}
        <Route path="/createpost/:postId" element={<CreatePost />} />
        <Route path="/login" element={<Login />} />
        <Route path="/search" element={<Search />} />

        {/* Handle the registration flow */}
        <Route path="/register" element={<Register />} />
        <Route path="/register2" element={<Register2 />} />
        <Route path="/register3" element={<Register3 />} />

        <Route path="/homepage" element={<HomePage />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/" element={<Landingpage />} /> {/* Default to landing page */}
        <Route path="/users/:userId" element={<UserProfile />} />
        <Route path="/post/:postId" element={<PostPage />} />
        <Route path="/my-posts" element={<MyPosts />} /> {/* Route for My Posts */}
        <Route path="/settings" element={<Settings />} /> {/* Route for Settings */}
      </Routes>
    </>
  );
}

export default App;
