import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Firebase configuration
import styled from 'styled-components';

function EditProfile({ currentUser, onClose }) {
  const [fullName, setFullName] = useState('');
  const [currentSchool, setCurrentSchool] = useState('');
  const [yearsOfTeaching, setYearsOfTeaching] = useState(0);
  const [type, setType] = useState(1); // 1 -> Grundskola, 2 -> Gymnasieskola
  const [searchTerm, setSearchTerm] = useState('');
  const [courses, setCourses] = useState([]);
  const [chosenSubjects, setChosenSubjects] = useState([]);
  const coursesBlockRef = useRef(null);

  useEffect(() => {
    // Fetch existing user data when the component mounts
    const fetchUserData = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFullName(userData.fullName || '');
          setCurrentSchool(userData.currentSchool || '');
          setYearsOfTeaching(userData.yearsOfTeaching || 0);
          setType(userData.selectedOption || 1);
          setChosenSubjects(userData.subjects || []);
        }
      }
    };
    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    // Fetch subjects from the "kurser" collection in Firestore
    const fetchCourses = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'kurser'));
        const coursesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setCourses(coursesData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchCourses();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCourseClick = (course) => {
    if (!chosenSubjects.includes(course.id)) {
      setChosenSubjects([...chosenSubjects, course.id]);
    }
    setSearchTerm(''); // Clear search after adding a course
  };

  const handleRemoveCourse = (courseId) => {
    setChosenSubjects(chosenSubjects.filter((id) => id !== courseId));
  };

  const handleSave = async () => {
    if (currentUser) {
      try {
        await updateDoc(doc(db, 'users', currentUser.uid), {
          fullName,
          currentSchool,
          yearsOfTeaching,
          selectedOption: type,
          subjects: chosenSubjects,
        });
        onClose(); // Close the form after saving
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  };

  const filteredCourses = courses
    .filter((course) =>
      course.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((course) => !chosenSubjects.includes(course.id)); // Exclude already chosen courses

  useEffect(() => {
    if (coursesBlockRef.current) {
      // Scroll to the bottom of the coursesBlock whenever chosenCourses changes
      coursesBlockRef.current.scrollTop = coursesBlockRef.current.scrollHeight;
    }
  }, [chosenSubjects]);

  return (
    <>
      <Overlay />
      <EditContainer>
        <Heading>Redigera Profil</Heading>
        <Form>
          <FormGroup>
            <Label>Namn och efternamn:</Label>
            <Input type="text" value={fullName} onChange={(e) => setFullName(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label>Skola:</Label>
            <Input type="text" value={currentSchool} onChange={(e) => setCurrentSchool(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label>Antal år som lärare:</Label>
            <Input
              type="number"
              value={yearsOfTeaching}
              onChange={(e) => setYearsOfTeaching(Number(e.target.value))}
            />
          </FormGroup>
          <FormGroup>
            <Label>Lärartyp:</Label>
            <Select value={type} onChange={(e) => setType(Number(e.target.value))}>
              <option value={1}>Grundskola</option>
              <option value={2}>Gymnasieskola</option>
            </Select>
          </FormGroup>

          {/* Subject Selection Block */}
          <FormGroup>
            <Label>Sök och välj dina ämnen*:</Label>
            <Input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Sök och välj ämnen"
            />
            {searchTerm && (
              <Suggestions>
                {filteredCourses.map((course) => (
                  <SuggestionItem key={course.id} onClick={() => handleCourseClick(course)}>
                    {course.name}
                  </SuggestionItem>
                ))}
              </Suggestions>
            )}
                <CoursesBlock ref={coursesBlockRef}>
                {chosenSubjects.length > 0 ? (
                    chosenSubjects.map((courseId) => {
                    const course = courses.find((c) => c.id === courseId);
                    
                    // Check if the course is found
                    if (!course) {
                        return null; // If course is not found, return null to avoid errors
                    }

                    return (
                        <ChosenCourseContainer key={courseId}>
                        <ChosenCourse>{course.name}</ChosenCourse>
                        <RemoveCross onClick={() => handleRemoveCourse(courseId)}>×</RemoveCross>
                        </ChosenCourseContainer>
                    );
                    })
                ) : (
                    <NoCoursesText>Inga ämnen tillagda ännu</NoCoursesText>
                )}
                </CoursesBlock>
          </FormGroup>

          <ButtonGroup>
            <SaveButton onClick={handleSave}>Spara</SaveButton>
            <CancelButton onClick={onClose}>Avbryt</CancelButton>
          </ButtonGroup>
        </Form>
      </EditContainer>
    </>
  );
}

export default EditProfile;

// Styled-components for EditProfile
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const EditContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  z-index: 1001;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 95%;
`;

const Label = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const Suggestions = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 5px;
  max-height: 150px;
  font-size: 13px;
  overflow-y: auto;
  z-index: 1000;
  position: absolute;
  width: 100%;
`;

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  text-align: left;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const CoursesBlock = styled.div`
  margin-top: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;
  max-height: 219px;
  background-color: #f7f7f7;
  text-align: center;
  width: 100%;
  overflow-y: auto;
`;

const ChosenCourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 5px;
`;

const ChosenCourse = styled.p`
  font-size: 13px;
  margin: 0;
`;

const RemoveCross = styled.span`
  cursor: pointer;
  color: red;
  font-size: 18px;
  margin-left: 10px;
`;

const NoCoursesText = styled.p`
  font-size: 13px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const SaveButton = styled.button`
  background-color: #0077b6;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #005f8a;
  }
`;

const CancelButton = styled.button`
  background-color: #ccc;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #aaa;
  }
`;
