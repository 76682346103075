import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import Layout from './Layout';
import { getAuth, signOut, deleteUser } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore'; 
import { Switch } from '@mui/material'; 

const Settings = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();

  const [anonymousMode, setAnonymousMode] = useState(false);
  const [hideEmail, setHideEmail] = useState(false);
  const [previousAnonymousMode, setPreviousAnonymousMode] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;

  // Fetch the user's current settings from Firestore
  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setAnonymousMode(userData.Anonym || false);
          setPreviousAnonymousMode(userData.Anonym || false);
          setHideEmail(userData.hideEmail || false);
        }
      } catch (error) {
        console.error('Error fetching user settings: ', error);
      }
    };

    if (user) {
      fetchUserSettings();
    }
  }, [db, user]);

  // Function to update the user settings in Firestore
  const updateUserSettings = async (field, value) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        [field]: value,
      });
    } catch (error) {
      console.error(`Error updating ${field}: `, error);
    }
  };

  // Function to check if the user has any published posts
  const userHasPosts = async () => {
    const postsRef = collection(db, 'posts');
    const q = query(postsRef, where('ownerUID', '==', user.uid));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  // Handle anonymous mode toggle
  const handleAnonymousModeChange = async (event) => {
    const newValue = event.target.checked;
    // Check if user is turning anonymous mode on and has published posts
    if (newValue && (await userHasPosts())) {
      const confirmed = window.confirm('Du har publicerade inlägg. Vill du göra dem osynliga?');
      if (!confirmed) {
        // User clicked "Avbryt", revert to previous state
        setAnonymousMode(previousAnonymousMode);
        return;
      }
    }

    // If the user confirmed or has no posts, update the setting
    setAnonymousMode(newValue);
    updateUserSettings('Anonym', newValue);
    setPreviousAnonymousMode(newValue); // Update the previous mode
  };

  // Handle hide email toggle
  const handleHideEmailChange = (event) => {
    const newValue = event.target.checked;
    setHideEmail(newValue);
    updateUserSettings('hideEmail', newValue);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm('Är du säker på att du vill ta bort ditt konto? Detta kan inte ångras.');
    if (confirmed) {
      try {
        const user = auth.currentUser;

        await user.getIdToken(true);

        await deleteUser(user);
        navigate('/login');
      } catch (error) {
        console.error('Error deleting account: ', error);
        alert('Något gick fel vid borttagning av kontot. Försök igen.');
      }
    }
  };

  return (
    <Layout>
      <SettingsWrapper>
        <SettingsContainer>
          <SettingsToggles>
            <ToggleSetting>
              <span>Anonymt läge</span>
              <Switch
                checked={anonymousMode}
                onChange={handleAnonymousModeChange}
                color="primary"
              />
            </ToggleSetting>

            <ToggleSetting>
              <span>Dölj epost</span>
              <Switch
                checked={hideEmail}
                onChange={handleHideEmailChange}
                color="primary"
              />
            </ToggleSetting>

            <ToggleSetting>
              <span>Du är fantastisk</span>
              <Switch
                checked={true} 
                color="primary"
                disabled={true} 
                sx={{
                  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
                    backgroundColor: '#8EB8E8',
                    opacity: 1,
                  },
                  '& .MuiSwitch-switchBase.Mui-disabled': {
                    color: '#1976d2',
                  },
                }}
              />
            </ToggleSetting>
          </SettingsToggles>

          <LogoutContainer>
            <DeleteButton onClick={handleDeleteAccount}>
              Ta bort konto
            </DeleteButton>
            <LogoutButton onClick={handleLogout}>
              Logga ut
            </LogoutButton>
          </LogoutContainer>
        </SettingsContainer>
      </SettingsWrapper>
    </Layout>
  );
};

// Parent container to ensure stacking of the child containers
const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 1000px;
  width: 700px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 20px;
`;

const SettingsToggles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const ToggleSetting = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  
  span {
    font-size: 16px;
  }
`;

const LogoutContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  gap: 10px;
`;

const LogoutButton = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  color: black;
  width: 110px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid #ddd;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const DeleteButton = styled(LogoutButton)`
  width: 140px;
  border: none;

  &:hover {
    background-color: white;
    text-decoration: underline;
    color: red;
  }
`;

export default Settings;
