import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase'; // Ensure this points to your Firebase configuration
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    // List of allowed paths for unauthenticated users
    const allowedPaths = ['/', '/login', '/register', '/register2', '/register3'];

    // Redirect to '/' if the user is not authenticated and trying to access a restricted page
    if (!currentUser && !allowedPaths.includes(location.pathname) && !loading) {
      navigate('/lgoin');
    }
  }, [currentUser, location, navigate]);

  const value = {
    currentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
