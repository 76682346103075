import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreatePostModal from './CreatePostModal'; // Import the modal
import styled from 'styled-components';
import { getProfilePicture } from './utils/getProfilePicture';

function EndPost() { 
  const navigate = useNavigate();
  const [showCreatePostModal, setShowCreatePostModal] = useState(false);

  const handleCreatePostClick = () => {
    setShowCreatePostModal((prev) => !prev);
  };

  return (
    <>
      <PostContainer>
      <CreatePostModal showModal={showCreatePostModal} closeModal={handleCreatePostClick} />
        <TopSection>
          <ProfileSection>
            <ProfilePic src={getProfilePicture('JonathanGörtzUID')} />
            <div>
              <FullName>Jonathan Görtz</FullName>
              <SubText>The Developer</SubText>
            </div>
          </ProfileSection>
        </TopSection>

        <HeaderSection>
          <Header>Inga fler inlägg</Header>
        </HeaderSection>

        <DescriptionSection>
          Det finns inga fler posts som matchar dina ämnen.
        </DescriptionSection>
        
        {/* Create Post Button */}
        <FooterSection>
          <CreatePostButton onClick={handleCreatePostClick}>
            Starta inlägg
          </CreatePostButton>
        </FooterSection>
      </PostContainer>
    </>
  );
}
export default EndPost;

const PostContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  position: relative;
  width: 700px;
  margin: auto;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px 5px 6px;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const ProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const FullName = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;
  display: block;
`;

const SubText = styled.p`
  font-size: 12px;
  color: #888;
  margin: 0;
  margin-top: 4px;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const Header = styled.h2`
  margin: 0;
  font-size: 24px;
  color: black;
`;

const DescriptionSection = styled.div`
  margin-top: 10px;
  color: #333;
  text-align: left;
  margin-bottom: 40px;
  margin-left: 30px;
  margin-right: 30px;
`;

const FooterSection = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const CreatePostButton = styled.button`
  background-color: #FFFFFF;
  color: black;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #5F6367;

  &:hover {
    background-color: #F0F0F0;
  }
`;
