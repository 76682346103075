import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function App() {
  const [openFAQ, setOpenFAQ] = useState(null);
  const navigate = useNavigate();

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const SkapaKonto = (index) => {
    navigate('/register');
  };

  const Login = (index) => {
    navigate('/login');
  };

  return (
    <Container>
      {/* First Block */}
      <ContentWrapper>
        <HeaderContainer>
          <LogoHeaderContainer>
            <img src="/teachrlogotype.png" alt="Teachr Logo" style={{ width: '52px', height: '52px' }} />
            <LogoText>Teachr</LogoText>
          </LogoHeaderContainer>

          <Nav>
            <ButtonGroup>
              <Button onClick={SkapaKonto}>Skapa ett konto</Button>
              <Button login onClick={Login}>Logga in</Button>
            </ButtonGroup>
          </Nav>
        </HeaderContainer>

        <MainSection>
          <TextSection>
            <Badge>
              <BadgeText>Årets tech</BadgeText>
              <BadgeRank>1st</BadgeRank>
            </Badge>
            <Title>Det snabbaste växande nätverket för lärare</Title>
            <Description>
              Ta materialet som Sveriges bästa lärare använder. Få tillgång till hundratals presentationer, övningar och planeringar på några minuter.
            </Description>
            <Offer>
              <p>Teachr är en ideell organisation. 
                <br></br>
                Allt för alltid gratis.</p>
            </Offer>
          </TextSection>
          <Currentmembers>
            <h1>150+</h1>
            <p style={{ marginTop: '1px', fontSize: '24px', fontWeight: 'normal' }}>lärare använder plattformen</p>
          </Currentmembers>




        </MainSection>

        <div style={{ textAlign: 'center', fontSize: '40px', fontWeight: 'bold', margin: '40px 0' }}>
          Hur fungerar hemsidan?
        </div>


        {/* Bottom Sections */}
        {/* Image on the left */}
        <BottomSection>
          <ImageSection style={{ marginRight: '20px', marginLeft: '0' }}>
            <img src="/homepagepreview.png" alt="Homepage Preview" style={{ width: '100%', height: '100%' }} />
          </ImageSection>
          <TextSection>
            <Title>Hitta materialet som du letar efter</Title>
            <Description>
              Vare sig du väljer att ta inspiration från andra lärare eller använder de färdiga lektionerna är upp till dig.
              <br></br>
              <br></br>
              Flödet är anpassat så att du bara får upp relevant innehåll.
            </Description>
          </TextSection>
        </BottomSection>


        {/* Image on the right */}
        <BottomSectionReversed>
          <TextSection>
            <Title>Dela med dig av dina lektioner</Title>
            <Description>
              Har du en lektion som du är extra nöjd med? Dela med dig av den till de andra 110 000 lärare i Sverige.
              <br></br>
              <br></br>
              Du kan även få feedback på dina lektioner och utvecklas som lärare.
              
            </Description>
          </TextSection>
          <ImageSection>
            <img src="/uploadpreview.png" alt="Homepage Preview" style={{ width: '100%', height: '100%' }} />
          </ImageSection>
        </BottomSectionReversed>

        {/* Image on the left */}
        <BottomSection>
          <ImageSection style={{ marginRight: '20px', marginLeft: '0' }}>
            <img src="/myprofilepreview.png" alt="Homepage Preview" style={{ width: '100%', height: '100%' }} />
          </ImageSection>
          <TextSection>
            <Title>Upptäck hur många som du hjälper.</Title>
            <Description>
              På din privata profil kan du se hur många som har använt dina lektioner.
              <br></br> 
              <br></br>
              Du är alltid anonym om du inte väljer att publicera innehåll eller kommentera på andras.
            </Description>
            <ButtonGroup>
              <Button style={{ marginTop: '15px' }} onClick={SkapaKonto}>
                Skapa ett konto
              </Button>

            </ButtonGroup>
          </TextSection>
        </BottomSection>

      </ContentWrapper>

      {/* Second Block - FAQs */}
      <ContentWrapper>
        <FAQSection>
          <FAQTitle>Vanliga frågor</FAQTitle>
          <FAQList>
            {[
              { question: 'Varför skapades Teachr?', answer: 'För att du som lärare ska komma hem till barnen i tid - utan att kompromissa kvaliteten av utbildningen.' },
              { question: 'Är jag anonym på plattformen?', answer: 'Ja. Du kan dock offentliggöra din profil i dina inställningar eller genom att lägga upp inlägg.' },
            ].map((faq, index) => (
              <FAQItem key={index} onClick={() => toggleFAQ(index)}>
                <FAQQuestion>{faq.question}</FAQQuestion>
                <FAQAnswer show={openFAQ === index}>{faq.answer}</FAQAnswer>
              </FAQItem>
            ))}
          </FAQList>
        </FAQSection>
      </ContentWrapper>

      {/* Footer Section */}
      <Footer>
        <FooterSection>
          <FooterLogo>
            <img src="/teachrlogotype.png" alt="Teachr Logo" />
            <span>Teachr</span>
          </FooterLogo>
        </FooterSection>

        <FooterSection>
          <FooterNav>
            <a>Nummer:<br></br>+046 094 70 22</a>
            <a>E-mail:<br></br>jonathangortz@teachr.se</a>
          </FooterNav>
        </FooterSection>

        <FooterSection>
          <FooterSocialLinks>
            <a href="https://www.linkedin.com/company/teachrse/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
              <img src="/linkedin.png" alt="LinkedIn" style={{ width: '40px', height: '40px' }} />
            </a>
          </FooterSocialLinks>
        </FooterSection>
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: solid white;
`;

const ContentWrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 40px;
  padding: 20px 0 0 0;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

const LogoHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LogoText = styled.span`
  width: 40px;
  height: 40px;
  margin-right: 5px;
  font-size: 2.5em;
  display: inline-flex;
  align-items: center;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

const ButtonGroup = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  color: black;

  ${({ login }) => 
    login
      ? `
      background-color: #fff;
      color: black;
      border: 1px solid #ddd;
    `
      : `
      background-color: #0077b6;
      border: 1px solid #ddd;
      color: #fff;
    `}

  &:hover {
    background-color: #F0F0F0;
    transform: translateY(-2px);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    color: black;
  }
`;

const MainSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: white;
`;

const Currentmembers = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  font-size: 5em;
  font-weight: bold;
  color: #0077b6;
  margin-right: 100px;

  h1 {
    margin: 0; /* Remove the default margin from the h1 element */
  }

  p {
    margin-top: 5px; /* Adjust this value as needed for the gap */
    font-size: 24px;
    font-weight: normal;
  }
`;



const BottomSection = styled(MainSection)`
  flex-direction: row;
`;

const BottomSectionReversed = styled(MainSection)`
  margin-right: 20px;
  margin-left: 0;
`;

const TextSection = styled.div`
  max-width: 50%;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
`;

const BadgeText = styled.span`
  margin-right: 10px;
  font-size: 1.2em;
`;

const BadgeRank = styled.h2`
  font-size: 1.5em;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin: 20px 0;
  transition: transform 0.3s ease;
`;

const Description = styled.p`
  font-size: 1.2em;
  margin-bottom: 20px;
`;

const Offer = styled.div`
  margin-top: 10px;
  font-size: 1.1em;
  color: black;
`;

const ImageSection = styled.div`
  max-width: 60%;
  max-height: 30%;
  background-color: #f0f0f0;
  margin-left: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;

  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  }
`;

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: white;
`;

const FAQTitle = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
  transition: transform 0.3s ease;
`;

const FAQList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FAQItem = styled.li`
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 50px;
  position: relative;
  width: 100%;

  &:hover {
    background-color: #f9f9f9;
    transform: scale(1.02);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

const FAQQuestion = styled.div`
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
`;

const FAQAnswer = styled.div`
  font-size: 1.1em;
  color: #666;
  width: 100%;
  text-align: left;
  opacity: ${({ show }) => (show ? '1' : '0')};
  max-height: ${({ show }) => (show ? '200px' : '0')};
  transition: opacity 0.5s ease, max-height 1s ease;
  overflow: hidden;
`;

const Footer = styled.footer`
  width: 100%;
  background-color: #fafafa;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  flex-wrap: wrap;
  text-align: center;
  color: #333;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  margin: 10px 0;
`;

const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
  }
`;

const FooterNav = styled.nav`
  display: flex;
  gap: 20px;
  justify-content: center;
  a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
    &:hover {
      color: #0077b6;
    }
  }
`;

const FooterSocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  a {
    color: #28c76f;
    font-size: 1.5em;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export default App;
