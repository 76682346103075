import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, updateDoc, increment, arrayUnion, arrayRemove } from 'firebase/firestore'; 
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { db } from './firebase'; 
import styled from 'styled-components';
import { getProfilePicture } from './utils/getProfilePicture'; // Import the utility function
import LinkPreview from './linkpreview';

function Post({ postId, showComments = false }) { 
  const { currentUser } = useAuth();
  const [post, setPost] = useState(null);
  const [postOwner, setPostOwner] = useState(null); 
  const [subjectName, setSubjectName] = useState(''); 
  const [isStarred, setIsStarred] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [fileLinks, setFileLinks] = useState([]); 
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [commentText, setCommentText] = useState('');
  const contentRef = useRef(null);
  const navigate = useNavigate();
  const storage = getStorage();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postDoc = await getDoc(doc(db, 'posts', postId));
        if (postDoc.exists()) {
          const postData = postDoc.data();
          const ownerDocRef = doc(db, 'users', postData.ownerUID);
          const ownerDoc = await getDoc(ownerDocRef);
  
          if (ownerDoc.exists()) {
            const ownerData = ownerDoc.data();
  
            // Check if the post owner's account is anonymous
            if (ownerData.Anonym) {
              setPost(null); // Do not show the post if the owner is anonymous
              return;
            }
  
            setPost(postData);
            setPostOwner(ownerData);
  
            // Update views only if the post owner is not the current user
            if (postData.ownerUID !== currentUser.uid) {
              await updateDoc(ownerDocRef, { views: increment(1) });
            }
  
            if (postData.subjectID) {
              fetchSubjectName(postData.subjectID);
              fetchFiles(postData.subjectID, postId);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };
    const checkIfStarred = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.starredPosts && userData.starredPosts.includes(postId)) {
            setIsStarred(true);
          }
        }
      }
    };


    fetchPost();
    checkIfStarred();
  }, [postId, currentUser]);

  const fetchSubjectName = async (subjectID) => {
    try {
      const subjectDoc = await getDoc(doc(db, 'kurser', subjectID));
      if (subjectDoc.exists()) {
        setSubjectName(subjectDoc.data().name);
      } else {
        setSubjectName('Unknown Subject');
      }
    } catch (error) {
      console.error('Error fetching subject:', error);
    }
  };

  const fetchFiles = async (subjectID, postId) => {
    try {
      const fileRef = ref(storage, `posts/${subjectID}/${postId}`);
      const fileList = await listAll(fileRef);

      const filePromises = fileList.items.map(async (itemRef) => {
        const downloadURL = await getDownloadURL(itemRef);
        return { name: itemRef.name, url: downloadURL };
      });

      const fetchedFiles = await Promise.all(filePromises);
      setFileLinks(fetchedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const toggleStar = async () => {
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    try {
      if (isStarred) {
        await updateDoc(userDocRef, {
          starredPosts: arrayRemove(postId),
        });
        setIsStarred(false);
      } else {
        await updateDoc(userDocRef, {
          starredPosts: arrayUnion(postId),
        });
        setIsStarred(true);
      }
    } catch (error) {
      console.error('Error updating starred posts:', error);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [post, isExpanded]);

  const handlePostClick = () => {
    navigate(`/post/${postId}`);
  };

  const handleUserClick = () => {
    if (post && post.ownerUID === currentUser.uid) {
      navigate('/myprofile');
    } else {
      navigate(`/users/${post.ownerUID}`);
    }
  };

  const truncateDescription = (text) => {
    if (text.length <= 150) return text;

    // Find the next space after 150 characters
    const truncatedText = text.slice(0, 150);
    const nextSpaceIndex = text.indexOf(' ', 150);

    // Return the text truncated at the next space, or the original 150 characters if no space is found
    return text.slice(0, nextSpaceIndex > 0 ? nextSpaceIndex : 150);
  };

  const renderDescription = () => {
    if (!post) return null;

    const truncatedText = truncateDescription(post.description);

    return (
      <div>
        <span
          dangerouslySetInnerHTML={{
            __html: showFullDescription
              ? post.description
              : truncatedText + (post.description.length > 150 ? '...' : ''),
          }}
        />
        {post.description.length > 150 && (
          <ShowMoreSpan onClick={() => setShowFullDescription(!showFullDescription)}>
            {showFullDescription ? 'Visa mindre' : 'Visa mer'}
          </ShowMoreSpan>
        )}
      </div>
    );
  };


  const handleCommentPublish = async () => {
    if (commentText.trim()) {
      try {
        await updateDoc(doc(db, 'posts', postId), {
          comments: arrayUnion({ text: commentText, user: currentUser.uid, createdAt: new Date() }),
        });
        setCommentText(''); // Clear the comment input
      } catch (error) {
        console.error('Error publishing comment:', error);
      }
    }
  };

  if (!post || !postOwner) {
    return <div></div>;
  }

  return (
    <>
      <PostContainer>
        <TopSection>
          <ProfileSection onClick={handleUserClick}>
            <ProfilePic src={getProfilePicture(postOwner)} />
            <div>
              <FullName>{postOwner.fullName}</FullName>
              <SubText>Lärare på {postOwner.currentSchool}</SubText>
            </div>
          </ProfileSection>
        </TopSection>
        <HeaderSection>
          <Header onClick={handlePostClick}>{post.title}</Header>
        </HeaderSection>
        <DescriptionSection>
          {renderDescription()}
        </DescriptionSection>
        <FooterSection>
          <StarButton onClick={toggleStar}>
            {isStarred ? (
              <video src="/starredanimation.mp4" autoPlay muted width="40px" height="40px" />
            ) : (
              <img src="/notstarred.png" alt="Not Starred" width="40px" height="40px" />
            )}
          </StarButton>


        </FooterSection>

        <ExpandButton isExpanded={isExpanded} onClick={toggleExpand}>
          <span>▼</span>
        </ExpandButton>

        <ExpandableContent isExpanded={isExpanded} contentHeight={contentHeight} ref={contentRef}>
          {post.links && post.links.length > 0 && <h3>Länkar</h3>}
          <ul>
            {post.links && post.links.map((link, index) => (
              <li key={index}>
                <LinkPreview url={link} />
              </li>
            ))}
          </ul>

          {fileLinks && fileLinks.length > 0 && <h3>Filer</h3>}
          <ul>
            {fileLinks.map((file, index) => (
              <li key={index}>
                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
              </li>
            ))}
          </ul>
        </ExpandableContent>
      </PostContainer>

      {showComments && (
        <CommentContainer>
          <UserProfilePic src={getProfilePicture(currentUser)} />
          <CommentInput
            placeholder="Lägg till kommentar"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            disabled={!currentUser}
          />
          <SendButtonContainer onClick={handleCommentPublish}>
            <SendImage src="/send.png" alt="Send" />
          </SendButtonContainer>
        </CommentContainer>
      )}
    </>
  );
}

export default Post;

// Styled-components

const PostContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  position: relative;
  width: 700px;
  margin: auto;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  box-sizing: border-box; 

  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); 
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px 5px 6px;
  
  &:hover {
    background-color: #F0F0F0;
  }
`;

const ProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const FullName = styled.span`
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;
  display: block;
`;

const SubText = styled.p`
  font-size: 12px;
  color: #888;
  margin: 0;
  margin-top: 4px;
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const Header = styled.h2`
  margin: 0;
  font-size: 24px;
  cursor: pointer;
  color: black;
`;

const DescriptionSection = styled.div`
  margin-top: 10px;
  cursor: pointer;
  color: #333;
  text-align: left;
  margin-bottom: 40px;
`;

const FooterSection = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
`;

const StarButton = styled.button`
  font-size: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ExpandButton = styled.button`
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background-color: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  padding: 2px 10px;
  z-index: 1;
  transition: transform 0.5s ease;
  
  &:hover {
    background-color: #e0e0e0;
  }

  span {
    display: inline-block;
    transition: transform 0.5s ease;
    transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

const ExpandableContent = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.isExpanded ? `${props.contentHeight}px` : '0')}; 
  transition: max-height 0.5s ease;

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap; 
  }

  li {
    flex: 0 0 45%; 
    max-width: 45%; 
    margin: 10px 5px; 
    box-sizing: border-box; 
  }

  h3 {
    margin-top: 20px;
  }
`;

const ShowMoreSpan = styled.span`
  color: #007bff;
  cursor: pointer;
  display: inline;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  margin-top: 0; 
  width: 51.2%;
  margin: 0 auto;
  background-color: #fff;
`;

const UserProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const CommentInput = styled.input`
  flex-grow: 1;
  border: none;
  border-radius: 9999px;
  padding: 10px 16px;
  background-color: #F0F0F0;
  outline: none;
`;

const SendButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SendImage = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

