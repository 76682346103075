// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import getStorage from Firebase
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAMui6EdJi1BZ7wXqRpk-htkeln5pdXHLA",
  authDomain: "teachrse.firebaseapp.com",
  databaseURL: "https://teachrse-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "teachrse",
  storageBucket: "teachrse.appspot.com",
  messagingSenderId: "495624320983",
  appId: "1:495624320983:web:634e309ea34b405de4bd92",
  measurementId: "G-BFBSXLDNRZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage
const googleProvider = new GoogleAuthProvider();

// Export the app and other Firebase services
export { app, auth, db, storage, googleProvider, analytics };
