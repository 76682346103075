import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, increment } from 'firebase/firestore';
import { db } from './firebase';
import Layout from './Layout';
import Post from './Post';
import styled from 'styled-components';
import { getProfilePicture } from './utils/getProfilePicture'; // Import the utility function
import { useAuth } from './AuthContext';

function UserProfile() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [subjectNames, setSubjectNames] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const currentUser = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserData(userData);

          console.log("User data:", userData);  // Debugging: Log userData to verify subjectID

          // Increment profileViews by 1
          await updateDoc(userDocRef, {
            profileViews: increment(1),
          });

          if (userData.subjects && userData.subjects.length > 0) {
            // Fetch subject names based on subjectID
            const subjectsCollection = collection(db, 'kurser');
            const subjectPromises = userData.subjects.map(async (subjectId) => {
              const subjectDoc = await getDoc(doc(subjectsCollection, subjectId));
              if (subjectDoc.exists()) {
                return subjectDoc.data().name;
              }
              return 'Unknown Subject';
            });

            const subjectNamesArray = await Promise.all(subjectPromises);
            console.log("Subject names array:", subjectNamesArray);  // Debugging: Log fetched subject names
            setSubjectNames(subjectNamesArray);
          } else {
            console.error('No subjects found for this user');
          }

          const postsQuery = query(
            collection(db, 'posts'),
            where('ownerUID', '==', userId)
          );
          const postDocs = await getDocs(postsQuery);
          const userPosts = postDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setPosts(userPosts);
          setFilteredPosts(userPosts);
        } else {
          console.error('User not found!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [userId]);



  useEffect(() => {
    const filtered = posts.filter(
      (post) =>
        post.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        (post.content && post.content.toLowerCase().includes(searchInput.toLowerCase()))
    );
    setFilteredPosts(filtered);
  }, [searchInput, posts]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  if (loading) {
    return <div></div>;
  }

  if (!userData) {
    return <div>User not found.</div>;
  }

  const handleSubjectClick = (subjectName) => {
    navigate(`/search?keyword=${subjectName}`);
  };

  return (
    <Layout>
      <ProfileContainer>
        <MainContent>
          <ProfileSection>
            <YearsTeachingContainer>
              <YearsText>{userData.yearsOfTeaching}</YearsText>
              <YearsTeaching>
                <span>{userData.yearsTeaching} +</span>
              </YearsTeaching>
            </YearsTeachingContainer>
            <HorizontalLine />
            <ProfilePic src={getProfilePicture(userData)} alt="Profile Picture" />
            <ProfileDetails>
              <NameAndJobContainer>
                <UserName>{userData.fullName || 'N/A'}</UserName>
                <JobDescription>
                {userData.selectedOption === 1 ? 'Grundskolelärare' : 'Gymnasielärare'} på {userData.currentSchool || 'N/A'}
                {userData.hideEmail === false && ` (${currentUser.email})`}
                </JobDescription>
              </NameAndJobContainer>
              <SubjectContainer>
                {subjectNames.map((subject, index) => (
                  <SubjectButton key={index} onClick={() => handleSubjectClick(subject)}>
                    {subject}
                  </SubjectButton>
                ))}
              </SubjectContainer>
            </ProfileDetails>
          </ProfileSection>

          {/* Posts Section */}
          <PostsSection>
            <SearchForm onSubmit={handleSearchSubmit}>
              <SearchInput
                type="text"
                placeholder="Sök bland inlägg"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </SearchForm>

            {filteredPosts.length > 0 ? (
              filteredPosts.map((post) => (
                <PostContainer key={post.id}>
                  <Post postId={post.id} style={{ width: '100px' }} />
                </PostContainer>
              ))
            ) : (
              <p>Inga sökträffar.</p>
            )}
          </PostsSection>
        </MainContent>
      </ProfileContainer>
    </Layout>
  );
}

// Styled-components

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  max-width: 1000px;
  width: 700px;
  margin: 0 auto;  // This centers the container horizontally
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PostsSection = styled.div`
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 700px;
  text-align: left;
`;

const PostContainer = styled.div`
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const ProfileSection = styled.div`
  text-align: left;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  padding-top: 130px;
  width: 100%;
`;

const ProfileDetails = styled.div`
  font-size: 20px;
  position: relative;
`;

const NameAndJobContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
`;

const HorizontalLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #ddd;
  top: 75px;
`;

const UserName = styled.h2`
  font-size: 26px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
`;

const JobDescription = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
`;

const ProfilePic = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 30px;
  left: 7%;
  border: 1px solid #ddd;
`;

const SubjectContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: inline-flex;
  botder: 1px solid #ddd;
  flex-wrap: wrap;
  gap: 10px;
`;

const SubjectButton = styled.button`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
  color: black;  

  &:hover {
    background-color: #F0F0F0;
  }
`;

const YearsTeachingContainer = styled.div`
  position: absolute;
  top: 23px;
  right: 20px;
  display: flex;
  align-items: center;
`;

const YearsText = styled.span`
  font-size: 24px;
  font-weight: bold;
  margin-right: 1px;
`;

const YearsTeaching = styled.div`
  width: 30px;
  height: 30px;
  font-size: 26px;
  font-weight: bold;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const SearchForm = styled.form`
  width: 300px;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

export default UserProfile;
