import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';
import { db } from './firebase';
import Layout from './Layout';
import styled from 'styled-components';
import Post from './Post'; // Assuming you have the Post component created

function Courses() {
  const { currentUser } = useAuth();
  const [starredPosts, setStarredPosts] = useState([]);
  const [noPosts, setNoPosts] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  // Fetch starred posts and owner data
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {
        try {
          // Fetch current user data
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();

            // Fetch starred posts manually by their ID
            if (data.starredPosts && data.starredPosts.length > 0) {
              const postPromises = data.starredPosts.map(async (postId) => {
                const postDoc = await getDoc(doc(db, 'posts', postId));
                if (postDoc.exists()) {
                  const postData = postDoc.data();

                  // Fetch owner's data
                  const ownerDoc = await getDoc(doc(db, 'users', postData.ownerUID));
                  let ownerData = {};
                  if (ownerDoc.exists()) {
                    ownerData = ownerDoc.data();
                  }

                  // Include owner data in the post object
                  return { id: postId, ...postData, ownerData };
                }
                return null;
              });

              const posts = await Promise.all(postPromises);
              const validPosts = posts.filter((post) => post !== null); // Filter out any null values (if post doesn't exist)
              setStarredPosts(validPosts);
              setFilteredPosts(validPosts);

              if (validPosts.length === 0) {
                setNoPosts(true);
              }
            } else {
              setNoPosts(true);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [currentUser]);

  // Filter posts based on search input
  useEffect(() => {
    const filtered = starredPosts.filter((post) => {
      const searchTerm = searchInput.toLowerCase();
      const titleMatch = post.title && post.title.toLowerCase().includes(searchTerm);
      const descriptionMatch = post.description && post.description.toLowerCase().includes(searchTerm);
      const ownerNameMatch =
        post.ownerData &&
        post.ownerData.fullName &&
        post.ownerData.fullName.toLowerCase().includes(searchTerm);
      const ownerSchoolMatch =
        post.ownerData &&
        post.ownerData.currentSchool &&
        post.ownerData.currentSchool.toLowerCase().includes(searchTerm);
      return titleMatch || descriptionMatch || ownerNameMatch || ownerSchoolMatch;
    });
    setFilteredPosts(filtered);
  }, [searchInput, starredPosts]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Layout>
      <Container>
        <SearchForm onSubmit={handleSearchSubmit}>
          <SearchInput
            type="text"
            placeholder="Sök bland sparade inlägg"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </SearchForm>

        {filteredPosts.length === 0 && noPosts ? (
          <Message>Klicka på stjärnan på ett inlägg för att spara det i biblioteket.</Message>
        ) : filteredPosts.length > 0 ? (
          filteredPosts.map((post) => (
            <PostContainer key={post.id}>
              <Post postId={post.id} />
            </PostContainer>
          ))
        ) : (
          <Message>Inga sökträffar.</Message>
        )}
      </Container>
    </Layout>
  );
}

// Styled-components for layout and search

const Container = styled.div`
  max-width: 800px;
  width: 740px;
  padding: 20px 0px; /* This ensures a similar padding from the top */
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: left;
  align-items: left;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-top: 20px; /* Ensures space from the topbar, assuming the topbar has a height of 60px */
`;


const SearchForm = styled.form`
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
  margin-left: 20px;

  
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:hover {
    background-color: #F0F0F0;
  }
`;

const PostContainer = styled.div`
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const Message = styled.p`
  font-size: 18px;
  color: #333;
  text-align: center;
`;

export default Courses;
