import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext'; // Import your authentication context
import { useNavigate } from 'react-router-dom'; // For redirecting to the CreatePost page
import { db } from './firebase'; // Import your Firebase configuration
import Layout from './Layout';
import Post from './Post'; // Import the Post component

function MyPosts() {
  const { currentUser } = useAuth(); // Get the current user from the Auth context
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // For redirecting to the CreatePost page

  useEffect(() => {
    const fetchMyPosts = async () => {
      if (!currentUser) return; // If the user is not logged in, exit the function

      try {
        const postsQuery = query(
          collection(db, 'posts'),
          where('ownerUID', '==', currentUser.uid) // Query posts by the logged-in user's ID
        );
        const postDocs = await getDocs(postsQuery);

        const userPosts = postDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPosts(userPosts); // Set the fetched posts in the state
      } catch (error) {
        console.error('Error fetching user posts:', error);
      }
      setLoading(false); // Set loading to false after fetching
    };

    fetchMyPosts();
  }, [currentUser]);

  // Function to handle post editing by redirecting to the CreatePost page
  const handleEdit = (post) => {
    navigate(`/createpost/${post.id}`); // Redirect to CreatePost with post ID
  };

  // Function to handle post deletion
  const handleDelete = async (postId) => {
    try {
      await deleteDoc(doc(db, 'posts', postId)); // Delete the post from Firestore
      setPosts(posts.filter(post => post.id !== postId)); // Remove the post from state
      console.log('Post successfully deleted!');
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  if (loading) {
    return <div>Loading your posts...</div>; // Loading state
  }

  return (
    <Layout>
      <div style={styles.pageContainer}>
        <h1>My Posts</h1>
        {posts.length > 0 ? (
          posts.map(post => (
            <div key={post.id} style={styles.postContainer}>
              <Post postId={post.id} /> {/* Display the post */}
              <div style={styles.actionButtons}>
                <button onClick={() => handleEdit(post)} style={styles.editButton}>
                  Edit
                </button>
                <button onClick={() => handleDelete(post.id)} style={styles.deleteButton}>
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>You haven't created any posts yet.</p>
        )}
      </div>
    </Layout>
  );
}

const styles = {
  pageContainer: {
    padding: '20px',
  },
  postContainer: {
    borderBottom: '1px solid #ddd',
    paddingBottom: '20px',
    marginBottom: '20px',
  },
  actionButtons: {
    marginTop: '10px',
  },
  editButton: {
    marginRight: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '5px 10px',
    border: 'none',
    cursor: 'pointer',
  },
  deleteButton: {
    backgroundColor: '#dc3545',
    color: '#fff',
    padding: '5px 10px',
    border: 'none',
    cursor: 'pointer',
  },
};

export default MyPosts;
