// Register3.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, googleProvider, db, storage } from './firebase'; // Firebase imports
import { 
  signInWithPopup, 
  createUserWithEmailAndPassword, 
  setPersistence, 
  browserLocalPersistence, 
  browserSessionPersistence 
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import bcrypt from 'bcryptjs';
import styled from 'styled-components';

function Register3() {
  const [fillLevel, setFillLevel] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const [selectedElement, setSelectedElement] = useState(null);
  const [errorMessage, setErrorMessage] = useState(''); // To display error messages
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility


  const navigate = useNavigate();
  const location = useLocation();
  const { fullName, currentSchool, selectedOption, yearsOfTeaching, subjects } = location.state || {};

  const totalFields = 3;
  const fieldsFilledCount = [email, password].filter(Boolean).length;

  const fillSpeed = 500;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFillLevel(fieldsFilledCount * (100 / totalFields));
    }, fillSpeed);

    return () => clearTimeout(timer);
  }, [fieldsFilledCount, totalFields, fillSpeed]);

  const uploadProfilePictureFromGoogle = async (userId, googlePhotoURL) => {
    if (!googlePhotoURL) return null;

    try {
      const response = await fetch(googlePhotoURL);
      const blob = await response.blob();

      // Corrected template literal with backticks
      const profilePicRef = ref(storage, `users/${userId}/profile.jpg`);
      await uploadBytes(profilePicRef, blob);
      const downloadURL = await getDownloadURL(profilePicRef);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading Google profile picture:', error);
      return null;
    }
  };

  const createUserDocument = async (user, profilePicURL) => {
    try {
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        fullName: user.displayName || fullName,
        currentSchool,
        selectedOption,
        yearsOfTeaching,
        subjects,
        profileViews: 0,
        starredPosts: [],
        views: 0,
        Anonym: true,
        hideEmail: true,
        createdAt: new Date(),
        photoURL: profilePicURL || user.photoURL || null,
      });
    } catch (error) {
      console.error('Error creating user document:', error);
    }
  };

  const validateRequiredFields = () => {
    if (!fullName || !currentSchool || !selectedOption || !yearsOfTeaching || !subjects) {
      setErrorMessage('Du har inte fyllt i den nödvändiga informationen från gamla steg.');
      return false;
    }
    return true;
  };

  const handleGoogleRegister = async () => {
    if (!validateRequiredFields()) return;

    const persistence = keepLoggedIn ? browserLocalPersistence : browserSessionPersistence;

    try {
      await setPersistence(auth, persistence);
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      const profilePicURL = await uploadProfilePictureFromGoogle(user.uid, user.photoURL);

      await createUserDocument(user, profilePicURL);

      navigate('/homepage');
    } catch (error) {
      console.error('Google login error:', error.message);
      alert('Registration failed. Redirecting to registration page.');
      navigate('/register');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleEmailRegister = async (event) => {
    event.preventDefault();
  
    if (!validateRequiredFields()) return;
  
    // Email validation
    if (!validateEmail(email)) {
      setErrorMessage('Ogiltig e-postadress. Vänligen ange en giltig e-postadress.');
      return;
    }
  
    const persistence = keepLoggedIn ? browserLocalPersistence : browserSessionPersistence;
  
    try {
      await setPersistence(auth, persistence);
  
      const salt = bcrypt.genSaltSync(10);
      const hashedPassword = bcrypt.hashSync(password, salt);
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, hashedPassword);
      const user = userCredential.user;
  
      await createUserDocument(user, null);
  
      navigate('/homepage');
    } catch (error) {
      console.error('Error during email registration:', error.message);
      setErrorMessage('Registreringen misslyckades. Försök igen.');
    }
  };


  return (
    <Container>
      <FormContainer>
        <LogoContainer>
          <LogoImage src="/teachrlogotype.png" alt="Teachr Logo" />
          <LogoText>Teachr</LogoText>
        </LogoContainer>
        
        <Form onSubmit={handleEmailRegister}>
          <Heading>Skapa ett konto</Heading>
          <FormContentContainer>
            <FormGroup>
              <Input
                type="email"
                placeholder="Skolmail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setSelectedElement('email')}
                selected={selectedElement === 'email'}
              />
            </FormGroup>
            <FormGroup>
            <PasswordContainer> {/* Wrap input and show password button */}
              <Input
                type={showPassword ? 'text' : 'password'} // Toggle between text and password
                placeholder="Lösenord"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={() => setSelectedElement('password')}
                selected={selectedElement === 'password'}
              />
            <ShowPasswordButton
              onClick={(e) => {
                e.preventDefault(); // Prevent form submission when toggling password visibility
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? 'Hide' : 'Show'}
            </ShowPasswordButton>
            </PasswordContainer>
          </FormGroup>

            <FormGroup>
              <OrContainer>
                <Line />
                <Or>eller</Or>
                <Line />
              </OrContainer>
              <SocialLoginContainer>
                <SocialImage
                  src="/google.png"
                  alt="Logga in via Google"
                  onClick={handleGoogleRegister}
                  selected={selectedElement === 'google'}  // This is where you can define when it's selected
                />

                <SocialImage
                  src="/microsoft.png"
                  alt="Logga in via Microsoft"
                />
              </SocialLoginContainer>
              <RememberMe>
                <label>
                  <input
                    type="checkbox"
                    checked={keepLoggedIn}
                    onChange={(e) => setKeepLoggedIn(e.target.checked)}
                  />
                  Kom ihåg mig
                </label>
              </RememberMe>
            </FormGroup>
            <Continue>
              <Square style={{ background: '#0077b6' }} />
              <Square style={{ background: '#0077b6' }} />
              <Square
                style={{
                  background: `linear-gradient(to top, #0077b6 ${fillLevel}%, #fff ${fillLevel}%)`,
                }}
              />
              <Button type="submit">Nästa</Button>
            </Continue>
          </FormContentContainer>
        </Form>
        {errorMessage && <Error>{errorMessage}</Error>}
      </FormContainer>
    </Container>
  );
}

// Styled-components

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
`;

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: -120px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const LogoImage = styled.img`
  width: 52px;
  height: 52px;
`;

const LogoText = styled.p`
  font-size: 52px;
  font-weight: bold;
  color: #000;
`;

const Form = styled.form`
  width: 350px;
  height: 450px;
  padding: 20px;
  border-radius: 20px;
  background-color: #f7f7f7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
`;

const Heading = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  font-size: 26px;
`;

const FormContentContainer = styled.div`
  width: 65%;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%;
`;

const Input = styled.input`
  width: 90%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 13px;
  ${(props) =>
    props.selected &&
    `
    border: 1px solid #0077b6;
    outline: none;
  `}

  &:hover {
    background-color: #F0F0F0;
  }
`;

const OrContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #ccc;
  margin: 0 20px;
`;

const Or = styled.p`
  margin: 0 10px;
  font-size: 14px;
  color: #888;
`;

const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
`;

const SocialImage = styled.img`
  width: 48%;
  cursor: pointer;
  border: 1px solid ${(props) => (props.selected ? '#0077b6' : '#ccc')};
  border-radius: 4px;
  background-color: #ffffff;
  ${(props) =>
    props.selected &&
    `
    outline: 1px solid #0077b6;
  `}

  &:hover {
    background-color: #F0F0F0;
  }
`;

const RememberMe = styled.div`
  margin-bottom: 15px;
  width: 100%;
  margin-top: 15px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Continue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 22.5px;
`;

const Button = styled.button`
  width: 50%;
  padding: 10px;
  background-color: #0077b6;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 25px;

  &:hover {
    background-color: #005f8a;
  }
`;

const Square = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  margin-left: 8px;
  background: #fff;
  transition: background 1s cubic-bezier(0.42, 0, 0.58, 1);
`;

const Error = styled.p`
  color: red;
`;

const PasswordContainer = styled.div`
  position: relative;
`;

const ShowPasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 58%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: #0077b6;
  cursor: pointer;
  font-size: 12px;
`;

export default Register3;
